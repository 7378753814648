import React from 'react';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

const useStyles = makeStyles({
    main: {
        lineHeight: '.75rem'
    },
    label: {
        display: "block",
        margin: "0",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    },
    input: {
        display: "block",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        width: "100%",
        height: "2.77083rem",
        margin: "0 0",
        padding: "0.66667rem",
        border: "2px solid #000",
        borderRadius: "8px",
        backgroundColor: "#fff",
        WebkitBoxShadow: "none",
        boxShadow: "none",
        fontFamily: "inherit",
        fontSize: "1rem",
        fontWeight: "normal",
        lineHeight: 1.5,
        color: "#000",
        WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
        transition: [
            "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            "box-shadow 0.5s, border-color 0.25s ease-in-out",
            "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
        ],
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        "&:disabled": {
            backgroundColor: "#e6e6e6",
            cursor: "not-allowed"
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #3CD568",
            backgroundColor: "#fff",
            WebkitBoxShadow: "none",
            boxShadow: "none",
            WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            transition: [
                "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
                "box-shadow 0.5s, border-color 0.25s ease-in-out",
                "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
            ]
        }
    },
    error: {
        lineHeight: '1rem',
        "& label, & p": {
            color: '#cc4b37!important'
        },
        "& input": {
            color: '#cc4b37!important',
            backgroundColor: '#faedeb',
            borderColor: '#cc4b37!important'
        },
        "& span": {
            color: '#cc4b37!important',
            fontSize: '.75rem'
        },
    },
    helper: {
        fontSize: '.75rem',
        lineHeight: '1.15rem',
        margin: '5px 0 0 0'
    }
});

export function MbTextField({ error, helper, label, type, register, inputProps, ...rest }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : classes.main}>
            <label className={classes.label}>{label}</label>
            <input
                className={classes.input}
                style={{ textTransform: type === 'email' ? 'lowercase' : 'none' }}
                type={type ? type : "text"}
                {...register}
                {...inputProps}
                {...rest}
            />
            {!!helper && <p className={classes.helper}>{helper}</p>}
            {!!error && <span>{error}</span>}
        </div>
    );
}

export function MbMaskedTextField({ error, helper, label, register, inputProps, ...rest }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : classes.main}>
            <label className={classes.label}>{label}</label>
            <PatternFormat
                className={classes.input}
                type="text"
                {...register}
                {...inputProps}
                {...rest}
            />
            {!!helper && <p className={classes.helper}>{helper}</p>}
            {!!error && <span>{error}</span>}
        </div>
    );
}

export function MbControlledMaskedTextField({ control, error, format, helper, label, mask, rules, onBlur, ...rest }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : classes.main}>
            <label className={classes.label}>{label}</label>
            <Controller
                control={control}
                rules={rules}
                render={({ field: { onChange, value, ref } }) => (
                    <PatternFormat
                        className={classes.input}
                        format={format}
                        mask={mask || '_'}
                        onBlur={onBlur}
                        value={value}
                        onValueChange={(v) => {
                            onChange(v.value)
                        }}
                    />
                )}
                {...rest}
            />
            {!!error && <span>{error}</span>}
            {!!helper && <p className={classes.helper}>{helper}</p>}
        </div>
    );
}