import React, { useState } from 'react';
import _ from 'lodash';
import AndroidIcon from '@mui/icons-material/Android';
import useAppInfo from '../../../hooks/appInfo.hook';
import DashboardLayout from "../../../layouts/Dashboard";
import Step1 from './Step1';
import Step2 from './Step2';

export default function PublishAndroid() {
    const { appInfo } = useAppInfo();

    const initialStep = appInfo.androidPublishStatus === 'first_publish_pending' ? 1 : 2;
    const [currentStep, setCurrentStep] = useState(initialStep);

    return (
        <DashboardLayout
            Icon={AndroidIcon}
            title="Aplicativo Android"
            subtitle="Envie a versão Android do seu aplicativo para a Google Play Store"
        >
            {currentStep === 1 && <Step1 nextStep={() => setCurrentStep(2)} />}
            {currentStep === 2 && <Step2 goToFirstStep={() => setCurrentStep(1)} />}
        </DashboardLayout >
    )
}