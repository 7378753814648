import { apiFetcher } from "./apiFetcher";

export const mobilizeiThemesService = {
    getThemes
};

function getThemes() {
    return apiFetcher('themes', {
        method: "GET"
    }).then(response => response);
}
