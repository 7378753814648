import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { mapIntegerInCentsToCurrency } from '../../utils/mappers';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    barCodeWrapper: {
        lineHeight: 0,
        wordBreak: 'break-all',
        "& img": {
            border: '1px solid #cccccc',
            width: '100%'
        }
    },
    button: {
        fontSize: 20,
        textTransform: 'none',
        width: '100%'
    }
}));

export default function BankSlipData({ title, text, disclaimer, invoice }) {
    const classes = useStyles();
    const [boletoCodeButtonText, setBoletoCodeButtonText] = useState('copiar números do código de barras');

    const navigate = useNavigate();

    const copyBarCodeNumbers = () => {
        navigator.clipboard.writeText(_.get(invoice, 'bankSlip.digitableLine', ''))
        setBoletoCodeButtonText('copiado!');

        setTimeout(() => {
            setBoletoCodeButtonText('copiar números do código de barras');
        }, 2000);
    }

    return (
        <Container maxWidth="sm">
            {!_.isEmpty(title) &&
                <Box mb={6}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </Box>
            }

            <Grid container direction="row" spacing={2}>

                {!_.isEmpty(text) &&
                    <Grid item xs={12}>
                        <Box marginBottom={8}>
                            <Typography variant="body1" align="center">
                                {text}
                            </Typography>
                        </Box>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="center">
                        <Typography variant="body1" align="center">
                            <strong>Valor: {mapIntegerInCentsToCurrency(invoice.totalInCents)}</strong>
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} className={classes.barCodeWrapper}>
                    <img src={_.get(invoice, 'bankSlip.barcode', '')} alt="Bank slip barcode" />
                    <Typography variant="caption" align="left">
                        {_.get(invoice, 'bankSlip.barcodeData', '')}
                    </Typography>

                </Grid>

                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="center" marginTop={4}>
                        <Button
                            className={classes.button}
                            disableElevation
                            onClick={() => copyBarCodeNumbers()}
                            variant="contained"
                        >
                            {boletoCodeButtonText}
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="center">
                        <Button
                            className={classes.button}
                            color="primary"
                            disableElevation
                            onClick={() => window.open(_.get(invoice, 'bankSlip.pdf', ''), '_blank').focus()}
                            variant="contained"
                        >
                            abrir boleto em outra aba
                        </Button>
                    </Box>
                </Grid>

                {!_.isEmpty(disclaimer) &&
                    <Grid item xs={12}>
                        <Box marginY={4}>
                            <Typography variant="body2" align="justify">
                                {disclaimer}
                            </Typography>
                        </Box>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="center">
                        <Button
                            color="primary"
                            disableElevation
                            onClick={() => navigate('/')}
                            variant="text"
                        >
                            voltar ao painel
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}