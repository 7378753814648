import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, Grid, Modal, Paper, CircularProgress } from "@mui/material";
import MaterialTable from "@material-table/core";
import _ from 'lodash';
import useAppInfo from '../hooks/appInfo.hook';
import { PLATFORMS } from '../utils/constants';
import { productsService } from '../services/products.service'
import { createWixFilter, createWixPaging, createWixQuery, createWixSortArray } from '../utils/wixTools';
import useAuthentication from '../hooks/authentication.hook';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 22,
    width: '100%'
  },
  modal: {
    position: 'absolute',
    width: '70%',
    padding: 0,
    top: '50%',
    left: '50%',
    maxHeight: '80%',
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)'
  },
  modalButton: {
    margin: 20
  },
  table: {
    boxShadow: 'none'
  },
  label: {
    display: "block",
    marginBottom: -15,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.8,
    color: "#000"
  }
}));

export default function ProductPicker({ onPicked, selectedProductId }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { fetchUser } = useAuthentication()
  const { appInfo } = useAppInfo()
  const platform = appInfo.platformId;

  useEffect(() => {
    if (!!selectedProductId) {
      setLoading(true);
      productsService.getProductById(
        appInfo.platformId,
        appInfo.storeId,
        selectedProductId,
        appInfo.accessToken
      )
        .then(response => {
          fetchUser();
          setSelectedProduct(response);
        })
        .catch(() => {
          setSelectedProduct(null);
        })
        .finally(() => {
          setLoading(false)
        })
    }

  }, [selectedProductId]);

  const handleRowClick = (data) => {
    setShowModal(false);
    setSelectedProduct(data);

    if (onPicked) {
      onPicked(data)
    }
  }

  return (
    <div>
      <Grid container>
        <label className={classes.label}>Produto</label>
        <Button
          className={classes.button}
          onClick={() => setShowModal(true)}
          variant="outlined"
          color="secondary"
        >
          {loading ?
            <CircularProgress size={20} /> :
            (_.isEmpty(selectedProduct) ? 'Selecionar produto' : _.get(selectedProduct, 'name', ''))
          }
        </Button>
      </Grid>

      <Modal
        disableAutoFocus
        disableEnforceFocus
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <Card className={classes.modal}>
          <MaterialTable
            columns={[
              {
                align: 'center',
                cellStyle: { width: 100 },
                title: 'Imagem',
                field: 'images',
                render: rowData => (
                  <img
                    style={{ height: 36 }}
                    src={_.get(rowData, 'images[0].imageUrl', 'assets/images/image_placeholder_350_350.png')}
                  />
                )
              },
              { title: 'Produto', field: 'name' }
            ]}
            components={{
              Container: props => <Paper {...props} elevation={0} />
            }}
            data={query =>
              new Promise((resolve, reject) => {
                let filters = {}

                switch(platform){
                  case PLATFORMS.TRAY:
                    filters = {
                      page: query.page + 1,
                      limit: query.pageSize,
                      sort: 'name_asc'
                    }
                    break;
                  case PLATFORMS.NUVEMSHOP:
                    filters = {
                      page: query.page + 1,
                      per_page: query.pageSize,
                      sort_by: 'alpha-ascending'
                    }
                    break;
                  case PLATFORMS.WIX:
                    filters = createWixQuery(
                      _.isEmpty(query.search) 
                        ? null
                        : createWixFilter({ "name": { "$contains": query.search }}),
                      createWixPaging(query.page + 1, query.pageSize),
                      createWixSortArray(['name-asc'])
                    )
                    break;
                  default:
                    break;
                }

                let queryMethod = null;
                if (_.isEmpty(query.search)) {
                  queryMethod = productsService.getProducts;
                } else {
                  queryMethod = productsService.searchProducts;
                }

                const options = { filters, query: query.search }

                queryMethod(appInfo.platformId, appInfo.storeId, options, appInfo.accessToken)
                  .then(response => {
                    fetchUser();
                    resolve({
                      data: response.products,
                      page: query.page,
                      totalCount: response.totalCount
                    })
                  })
                  .catch(() => {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0
                    })
                  })
              })
            }
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              toolbar: {
                searchPlaceholder: 'Buscar',
                searchTooltip: 'Buscar'
              },
              body: {
                emptyDataSourceMessage: 'Sem produtos para exibir'
              }
            }}
            onRowClick={(e, data) => handleRowClick(data)}
            options={{
              debounceInterval: 500,
              pageSize: 5,
              pageSizeOptions: [],
              header: false
            }}
            title="Produtos"
          />
          <Grid container justifyContent='center'>
            <Button
              color="secondary"
              className={classes.modalButton} onClick={() => setShowModal(false)}
              variant='outlined'
            >
              Cancelar
            </Button>
          </Grid>
        </Card>
      </Modal>
    </div>
  );
}