import { apiFetcher } from "./apiFetcher";
import qs from "qs";

export const nuvemshopService = {
    getProducts,
    getProductById,
    getProductVariants,
    searchProducts,
    getCategories,
    getAllCategories
};

function getProducts(storeId, options, accessToken) {
    return apiFetcher(
        `platforms/nuvemshop/api_forward/${storeId}/products?published=true&${qs.stringify(options.filters)}`,
        { method: "GET", headers: { 'Authentication': accessToken } },
        false,
        true
    )
}

function getProductById(storeId, productId, accessToken) {
    return apiFetcher(
        `platforms/nuvemshop/api_forward/${storeId}/products/${productId}`,
        { method: "GET", headers: { 'Authentication': accessToken } }
    )
}

function getProductVariants(storeId, productId, accessToken) {
    return apiFetcher(
        `platforms/nuvemshop/api_forward/${storeId}/products/${productId}/variants?per_page=200`,
        { method: "GET", headers: { 'Authentication': accessToken } }
    )
}

function searchProducts(storeId, options, accessToken) {
    return apiFetcher(
        `platforms/nuvemshop/api_forward/${storeId}/products?published=true&q=${options.query}&${qs.stringify(options.filters)}`,
        { method: "GET", headers: { 'Authentication': accessToken } },
        false,
        true
    );
}

function getCategories(storeId, filters, accessToken) {
    return apiFetcher(
        `platforms/nuvemshop/api_forward/${storeId}/categories?per_page=200&` + qs.stringify(filters),
        { method: "GET", headers: { 'Authentication': accessToken } }
    )
}

function getAllCategories(storeId, page = 1, categoriesArray = [], accessToken) {
    return getCategories(storeId, { page }, accessToken)
        .then(response => {
            categoriesArray = categoriesArray.concat(response);
            page += 1;
            return getAllCategories(storeId, page, categoriesArray, accessToken);
        })
        .catch(() => {
            return categoriesArray;
        })
}