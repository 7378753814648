import { apiFetcher } from "./apiFetcher";
import qs from "qs";

export const trayService = {
    getProducts,
    getProductById,
    getProductVariants,
    searchProducts,
    getAllCategories
};

function getProducts(storeId, options) {
    const url = `platforms/tray/api_forward/${storeId}/products?${qs.stringify(options.filters)}`;

    return apiFetcher(url, { method: "GET" });
}

function getProductById(storeId, productId) {
    return apiFetcher(`platforms/tray/api_forward/${storeId}/products/${productId}`, {
        method: "GET"
    }).then(response => {
        return response.Product;
    });
}

function getProductVariants(storeId, productId) {
    return apiFetcher(
        `platforms/tray/api_forward/${storeId}/variants?limit=50&product_id=${productId}`,
        {
            method: "GET"
        }
    ).then(response => {
        return response.Variants;
    });
}

function searchProducts(storeId, options) {
    const url = `platforms/tray/api_forward/${storeId}/search?query=${options.query}&${qs.stringify(options.filters)}`;

    return apiFetcher(url, { method: "GET" });
}

function getAllCategories(storeId) {
    return apiFetcher(`platforms/tray/api_forward/${storeId}/categories/tree?active=1`, { method: "GET" });
}