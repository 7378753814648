import React, { useState } from 'react';
import _ from 'lodash';
import MainLayout from "../../../layouts/main";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MbTextField } from '../../../components/MbTextFields';
import { mobilizeiUsersService } from '../../../services/mobilizei/users.service';
import useAuthentication from '../../../hooks/authentication.hook';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { showApiError } from '../../../utils/errors';
import HelloContainer from '../HelloContainer';

export default function Login() {
    const { handleSubmit, register, formState: { errors } } = useForm();

    const [isLoading, setIsLoading] = useState(false)
    const { setToken } = useAuthentication()

    const onSubmit = (data) => {
        setIsLoading(true);

        mobilizeiUsersService.signIn(data.email, data.password)
            .then(response => {
                setToken(response.token);
            })
            .catch(e => {
                showApiError(e.error);
                setIsLoading(false)
            })
    }

    return (
        <MainLayout>
            <Grid container display="flex" direction="row" spacing={2} paddingX={{ xs: 2, lg: 20 }}>
                <Grid item xs={12} lg={6}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 6,
                        paddingBottom: 10,
                        minHeight: '100vh'
                    }}
                >
                    <Typography component="h1" variant="h2">
                        Login
                    </Typography>
                    <Typography sx={{ mt: 1.5 }} component="h2" fontWeight="normal" textAlign="center" variant="h6">
                        Ainda não tem uma conta?{' '}
                        <Link href="/registrar" variant="h6">
                            Registre-se.
                        </Link>
                    </Typography>

                    <Grid
                        container
                        component="form"
                        spacing={2}
                        maxWidth={"sm"}
                        direction={'row'}
                        justifyContent={'center'}
                        sx={{ mt: 4 }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid item xs={12} sm={9}>
                            <MbTextField
                                error={errors.email?.message}
                                label="E-mail *"
                                register={register("email", {
                                    required: "O e-mail é obrigatório.",
                                    pattern: {
                                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}|s$/,
                                        message: "O e-mail não é válido."
                                    }
                                })}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <MbTextField
                                error={errors.password?.message}
                                label="Senha *"
                                register={register("password", {
                                    required: "A senha é obrigatória."
                                })}
                                type="password"
                            />
                        </Grid>
                        {isLoading ? (
                            <Grid
                                container
                                justifyContent='center'
                                sx={{ mt: 3 }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={6} sm={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ fontSize: '18px', mt: 3 }}
                                    >
                                        Entrar
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Link href="/recuperar-senha" variant="body1">
                                            Esqueceu sua senha?
                                        </Link>
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

                <HelloContainer />
            </Grid>
        </MainLayout >
    );
}