import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import MainLayout from "../../../layouts/main";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MbTextField } from '../../../components/MbTextFields';
import { mobilizeiUsersService } from '../../../services/mobilizei/users.service';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { showApiError } from '../../../utils/errors';
import { useNavigate } from 'react-router-dom';
import HelloContainer from '../HelloContainer';

export default function Login() {
    const navigate = useNavigate();
    const { handleSubmit, register, watch, formState: { errors } } = useForm();

    const [isLoading, setIsLoading] = useState(false)
    const [isPasswordReseted, setIsPasswordReseted] = useState(false)

    const [userId, setUserId] = useState()
    const [token, setToken] = useState()

    const onSubmit = (data) => {
        setIsLoading(true);

        mobilizeiUsersService.resetPassword(data.password, token, userId)
            .then(() => {
                setIsPasswordReseted(true);
            })
            .catch(e => {
                showApiError(e?.error);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const urlToken = urlParams.get('token');
        const urlUserId = urlParams.get('uid');

        if (urlToken && urlUserId) {
            setToken(urlToken);
            setUserId(urlUserId);
        } else {
            navigate('/login');
        }
    }, [])

    return (
        <MainLayout>
            <Grid container display="flex" direction="row" spacing={2} paddingX={{ xs: 2, lg: 20 }}>
                <Grid item xs={12} lg={6}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 6,
                        paddingBottom: 10,
                        minHeight: '100vh'
                    }}
                >
                    <Typography component="h1" variant="h2">
                        Alterar senha
                    </Typography>

                    {isPasswordReseted ? (
                        <Grid
                            container
                            direction={'row'}
                            item
                            justifyContent={'center'}
                            sm={8}
                            xs={10}
                        >
                            <Grid item sx={{ mt: 4 }}>
                                <Typography sx={{ mt: 1.5 }} component="h2" fontWeight="normal" textAlign="center" variant="h6">
                                    Senha alterada com sucesso!
                                </Typography>
                                <Typography sx={{ mt: 2 }} component="h2" fontWeight="normal" textAlign="center" variant="h6">
                                    Agora você já pode utilizar a sua nova senha para efetuar
                                    o login na sua conta.
                                </Typography>
                            </Grid>

                            <Grid item sx={{ mt: 4 }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Link href="/login" variant="body1">
                                        Fazer login
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Typography sx={{ mt: 1.5 }} component="h2" fontWeight="normal" textAlign="center" variant="h6">
                                Digite sua nova senha nos campos abaixo.
                            </Typography>

                            <Grid
                                container
                                component="form"
                                spacing={2}
                                maxWidth={"sm"}
                                direction={'row'}
                                justifyContent={'center'}
                                sx={{ mt: 4 }}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Grid item xs={12} sm={9}>
                                    <MbTextField
                                        error={errors.password?.message}
                                        label="Sua nova senha *"
                                        register={register("password", {
                                            required: "A senha é obrigatória."
                                        })}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <MbTextField
                                        error={errors.passwordConfirmation?.message}
                                        label="Confirme sua nova senha *"
                                        register={register("passwordConfirmation", {
                                            required: "A confirmação de senha é obrigatória.",
                                            validate: (val) => {
                                                if (watch('password') != val) {
                                                    return "A senha e a confirmação de senha devem ser iguais.";
                                                }
                                            },
                                        })}
                                        type="password"
                                    />
                                </Grid>
                                {isLoading ? (
                                    <Grid
                                        container
                                        justifyContent='center'
                                        sx={{ mt: 3 }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ fontSize: '18px', mt: 3 }}
                                        >
                                            Enviar
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>

                <HelloContainer />
            </Grid>
        </MainLayout >
    );
}