import React from 'react';
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useUser } from '../../../contexts/user.context';
import config from '../../../utils/config';

export default function InstructionsCard() {
    const { user } = useUser();

    return (
        <Box sx={{
            paddingX: { sm: 8, xs: 0 }
        }}>
            <Card elevation={1} sx={{ background: '#eeeeee', mt: 4 }}>
                <CardContent sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Box marginLeft={4}>
                        <Typography style={{ color: "#000" }} align="center" variant="h6" >
                            Como incluir o envio de uma notificação push em um fluxo da Sellflux
                        </Typography>
                        <Typography style={{ color: "#000" }} align="justify" variant="subtitle1" >
                            <ol>
                                <li>Incluir uma stage "Add tag" adicionando a tag da respectiva notificação ao lead.</li>
                                <li>Após a inclusão da tag, incluir a stage de integração Webhook com o seguinte link de destino: <br />{config.MOBILIZEI_API_BASE_URL}/platforms/{user?.store?.platformId}/stores/{user?.store?.id}/features/sellflux/webhook</li>
                                <li>Incluir uma stage "Remover tag" removendo a tag da respectiva notificação do lead. Essa etapa é necessária para garantir o envio da notificação correta em todos os fluxos.</li>
                            </ol>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}