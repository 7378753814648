import moment from 'moment';

export const formatTimestamp = (timestamp, format) => {
    if(!timestamp) return null;

    const jsDate = new Date(timestamp)
    return moment(jsDate).format(format);
}

export const formatFileName = (text) => {
    if(text){
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replaceAll('-', '').replaceAll(" ", "")
    }

    return text
}