import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function RemoveCreditCardDialog({ isOpen, setIsOpen, onConfirm }) {
    return (
        <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle display='flex' alignItems='center'>
                <WarningAmberIcon sx={{ mr: 1 }} />
                <Typography variant="title1">
                    Remover cartão de crédito?
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Typography align="justify" variant="body1" sx={{ mb: 2 }}>
                    Ao remover o seu cartão de crédito a assinatura da Mobilizei deixará de ser cobrada automaticamente.
                </Typography>
                <Typography align="justify" variant="body1">
                    Todo o mês você receberá uma fatura no seu e-mail e poderá pagá-la via cartão de crédito, boleto ou Pix para continuar utilizando o serviço.
                </Typography>
            </DialogContent>

            <DialogActions sx={{ py: 2, px: 3 }}>
                <Button onClick={() => setIsOpen(false)}>
                    Cancelar
                </Button>
                <Button variant="contained" onClick={onConfirm}>
                    Remover cartão
                </Button>
            </DialogActions>
        </Dialog>
    )
}