import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function SubscriptionCancelDialog({ isOpen, setIsOpen, onConfirm, expiresAt }) {
    return (
        <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle display='flex' alignItems='center'>
                <WarningAmberIcon sx={{ mr: 1 }} />
                <Typography variant="title1">
                    Cancelar sua assinatura?
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Typography align="justify" variant="body1" sx={{ mb: 2 }}>
                    Ao cancelar sua assinatura, ela permanecerá ativa até o dia {expiresAt} e nenhuma nova cobrança ou fatura serão geradas.
                </Typography>
                <Typography align="justify" variant="body1">
                    Após essa data, seus aplicativos serão desativados e você não poderá mais acessar o seu painel de controle.
                </Typography>
            </DialogContent>

            <DialogActions sx={{ py: 2, px: 3 }}>
                <Button onClick={() => setIsOpen(false)}>
                    não, desejo continuar com a minha assinatura
                </Button>
                <Button variant="contained" onClick={onConfirm}>
                    sim, desejo cancelar a minha assinatura
                </Button>
            </DialogActions>
        </Dialog>
    )
}