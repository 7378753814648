import React from 'react';
import _ from 'lodash';
import { Typography, Link, Box } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import { useUser } from "../../contexts/user.context";

export default function MissingDataCard() {
    const { user } = useUser();

    function isMissingGeneralData() {
        return (
            _.isEmpty(user?.store?.title) ||
            _.isEmpty(user?.store?.shortDescription) ||
            _.isEmpty(user?.store?.description) ||
            _.isEmpty(user?.store?.privacyPolicyUrl) ||
            _.isEmpty(user?.store?.keywords)
        )
    }

    function isMissingImageData() {
        return (
            _.isEmpty(user?.store?.launchScreenUrl) ||
            _.isEmpty(user?.store?.iconUrl) ||
            _.isEmpty(user?.store?.featureGraphicUrl)
        )
    }

    return (
        <Box display='flex' alignItems='center' textAlign='center' sx={{ mt: 8, px: 4, py: 2, background: '#ef9a9a' }}>
            <WarningIcon style={{ color: "#B71C1C", fontSize: 35 }} />
            <Typography color='#b71c1c' align="justify" variant="subtitle1" marginLeft={4} >
                Existem informações obrigatórias para a publicação do seu aplicativo que ainda não estão preenchidas. Assim que você preencher os campos faltantes poderá solicitar a publicação do seu aplicativo.

                {isMissingGeneralData() && (
                    <div>
                        <p>Na seção <Link style={{ color: "#B71C1C" }} to="/general">"Dados gerais"</Link> falta:</p>
                        <ul>
                            {_.isEmpty(user?.store?.title) && (<li>Título</li>)}
                            {_.isEmpty(user?.store?.shortDescription) && (<li>Descrição curta</li>)}
                            {_.isEmpty(user?.store?.description) && (<li>Descrição detalhada</li>)}
                            {_.isEmpty(user?.store?.privacyPolicyUrl) && (<li>Url da Política de Privacidade</li>)}
                            {_.isEmpty(user?.store?.keywords) && (<li>Palavras chave</li>)}
                        </ul>
                    </div>
                )}

                {isMissingImageData() && (
                    <div>
                        <p>Na seção <Link style={{ color: "#B71C1C" }} to="/images">"Imagens"</Link> falta:</p>
                        <ul>
                            {_.isEmpty(user?.store?.iconUrl) && (<li>Ícone</li>)}
                            {_.isEmpty(user?.store?.launchScreenUrl) && (<li>Tela de introdução</li>)}
                            {_.isEmpty(user?.store?.featureGraphicUrl) && (<li>Banner da Play Store</li>)}
                        </ul>
                    </div>
                )}
            </Typography>
        </Box>
    )
}