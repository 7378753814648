import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import PushiOS from './PushiOS';
import PushAndroid from './PushAndroid';

const useStyles = makeStyles(() => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    previeweriPhone: {
        background: `url(${require('../../../../assets/img/push/push_preview_iphone.png')}) no-repeat right top`,
        backgroundSize: 'cover',
        position: 'relative',
        height: 550,
        width: 400
    },
    previewerAndroid: {
        background: `url(${require('../../../../assets/img/push/push_preview_android.png')}) no-repeat right top`,
        backgroundSize: 'cover',
        position: 'relative',
        height: 550,
        width: 400
    },
    note: {
        maxWidth: 400,
        padding: '0 10px',
        textAlign: 'justify'
    },
    footer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        width: 120,
        marginBottom: 20
    },
    buttonSelected: {
        background: '#e0e0e0',
        width: 120,
        marginBottom: 20
    }
}));

export default function PushPreviewer({ payload }) {
    const classes = useStyles();
    const [selectedPlatform, setSelectedPlatform] = useState('android');

    const noteText = "* A forma como a notificação push será exibida pode variar de acordo com o modelo do celular e o seu sistema operacional";

    return (
        <Box className={classes.container} sx={{ marginTop: 4 }}>
            {selectedPlatform === 'ios' ? (
                <div className={classes.previeweriPhone}>
                    <PushiOS payload={payload} />
                </div>
            ) : (
                <div className={classes.previewerAndroid}>
                    <PushAndroid payload={payload} />
                </div>
            )}

            <div className={classes.footer}>
                <Typography className={classes.note} variant="caption">{noteText}</Typography>
                <ButtonGroup size="large" color="secondary" aria-label="large outlined primary button group" sx={{ marginY: 6 }}>
                    <Button className={selectedPlatform === 'android' ? classes.buttonSelected : classes.button} onClick={() => setSelectedPlatform('android')}>Android</Button>
                    <Button className={selectedPlatform === 'ios' ? classes.buttonSelected : classes.button} onClick={() => setSelectedPlatform('ios')}>iOS</Button>
                </ButtonGroup>
            </div>

        </Box>
    )
}
