import React from 'react';
import { Card, Link, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PublishIcon from '@mui/icons-material/Publish';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GetAppIcon from '@mui/icons-material/GetApp';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles(() => ({
    cardContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        wordBreak: 'break-word'
    }
}))

export default function AppStatus(props) {
    const classes = useStyles();

    if (props.publishStatus === 'first_publish_pending') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#f5f5f5', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <PublishIcon style={{ color: "#3C4252", fontSize: 35, marginRight: 16 }} />
                    <Typography align="justify" variant="body2">
                        Você ainda não solicitou a publicação do seu aplicativo {props.platformName}!{' '}
                        <Link to={`/${props.platformName.toLowerCase()}`}>Clique aqui</Link> e envie
                        seu aplicativo para a {props.storeName}.
                    </Typography>
                </div>
            </Card>
        )
    }

    if (props.isAppBeingPublished && props.downloadUrl.length === 0) {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#a5d6a7', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <CheckCircleIcon style={{ color: "#22543d", fontSize: 35, marginRight: 16 }} />
                    <div>
                        <Typography sx={{ color: '#1b5e20' }} align="justify" variant="body2">
                            Seu aplicativo {props.platformName} está em aprovação e deve levar cerca de {props.deadlineInDays} dias
                            úteis para ser liberado. Este prazo pode variar para mais ou para menos de acordo com a velocidade da
                            revisão realizada {props.platformName === 'iOS' ? 'pela Apple' : 'pelo Google'}.
                        </Typography>

                        <Typography sx={{ color: '#1b5e20', marginTop: 1 }} align="justify" variant="body2">
                            Te avisaremos por email assim que seu aplicativo estiver disponível para download!
                        </Typography>

                        {props.lastPublishRequestedAt &&
                            <Typography sx={{ color: '#1b5e20', marginTop: 1 }} align="left" variant="body2">
                                Publicação solicitada em: {props.lastPublishRequestedAt}
                            </Typography>
                        }
                    </div>
                </div>
            </Card>
        )
    }

    if (props.isAppBeingPublished && props.downloadUrl.length > 0) {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#a5d6a7', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <CheckCircleIcon style={{ color: "#22543d", fontSize: 35, marginRight: 16 }} />
                    <div>
                        <Typography sx={{ color: '#1b5e20' }} className="text-green-900" align="justify" variant="body2">
                            Uma atualização do seu aplicativo {props.platformName} está em aprovação e deve levar cerca 
                            de {props.deadlineInDays} dias úteis para ser liberada. Este prazo pode variar para mais ou para 
                            menos de acordo com a velocidade da revisão realizada {props.platformName === 'iOS' ? 'pela Apple' : 'pelo Google'}.
                        </Typography>

                        <Typography sx={{ color: '#1b5e20', marginTop: 1 }} align="justify" variant="body2">
                            A versão atual continua disponível para download no link abaixo:
                        </Typography>

                        <Typography sx={{ color: '#1b5e20', marginTop: 1 }} align="left" variant="body2">
                            <a href={props.downloadUrl} rel="noopener noreferrer" target="_blank" style={{ color: '#21543d' }}>{props.downloadUrl}</a>
                        </Typography>

                        {props.lastPublishRequestedAt &&
                            <Typography sx={{ color: '#1b5e20', marginTop: 1 }} align="left" variant="body2">
                                Publicação solicitada em: {props.lastPublishRequestedAt}
                            </Typography>
                        }
                    </div>
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'published' && props.downloadUrl.length > 0) {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#f5f5f5', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <GetAppIcon style={{ color: "#3C4252", fontSize: 35, marginRight: 16 }} />
                    <div>
                        <Typography align="justify" variant="body2">
                            Seu aplicativo {props.platformName} está publicado e disponível para download no link abaixo:
                        </Typography>
                        <Typography sx={{ marginTop: 1 }} color="primary" align="left" variant="body2">
                            <a href={props.downloadUrl} rel="noopener noreferrer" target="_blank">{props.downloadUrl}</a>
                        </Typography>
                    </div>
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'pending_update') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#fff9c4', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <WarningIcon style={{ color: "#744210", fontSize: 35, marginRight: 16 }} />
                    <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                        Você realizou alterações que exigem o lançamento de uma atualização para o seu 
                        aplicativo! <Link to={`/${props.platformName.toLowerCase()}`}>Clique aqui</Link> para 
                        enviar uma atualização do seu app para a {props.storeName}.
                    </Typography>
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'developer_account_pending') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#fff9c4', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <WarningIcon style={{ color: "#744210", fontSize: 35, marginRight: 16 }} />
                    {props.platformName.toLowerCase() === 'android' ? (
                        <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                            Você precisa criar a sua conta de desenvolvedor do Google Play organizacional para seguir com a publicação do seu aplicativo Android!{' '}
                            <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-google-play" target="_blank">Clique aqui</a>{' '}
                            e veja como criar esta conta.
                        </Typography>
                    ) : (
                        <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                            Você precisa criar a sua conta de desenvolvedor Apple empresarial para seguir com a publicação do seu aplicativo iOS!{' '}
                            <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-apple" target="_blank">Clique aqui</a>{' '}
                            e veja como criar esta conta.
                        </Typography>
                    )}
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'pending_google_play_developer_account_verification') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#fff9c4', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <WarningIcon style={{ color: "#744210", fontSize: 35, marginRight: 16 }} />
                    <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                        Você deve realizar a verificação da sua conta de desenvolvedor Google Play organizacional para que seja possível publicar o seu aplicativo. Acesse sua conta por meio <a href="https://play.google.com/console" target="_blank">deste link</a> e confira o aviso exibido na tela inicial.
                    </Typography>
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'pending_apple_account_renewal') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#fff9c4', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <WarningIcon style={{ color: "#744210", fontSize: 35, marginRight: 16 }} />
                    <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                        A assinatura da sua conta de desenvolvedor iOS expirou e com isso <b>a Apple tirou seu app do ar!</b> Para disponibilizar o seu aplicativo iOS para download novamente, você deve acessar a sua conta Apple por meio <a href="https://developer.apple.com/account" target="_blank">deste link</a> e efetuar a renovação da sua assinatura.
                    </Typography>
                </div>
            </Card>
        )
    }

    if (props.publishStatus === 'pending_apple_account_terms_agreement') {
        return (
            <Card elevation={1} sx={{ alignItems: 'center', background: '#fff9c4', display: 'flex', marginTop: 2, minHeight: 91 }}>
                <div className={classes.cardContent}>
                    <WarningIcon style={{ color: "#744210", fontSize: 35, marginRight: 16 }} />
                    <Typography style={{ color: "#744210" }} align="justify" variant="body2">
                        Existe uma atualização de contrato que deve ser aceita por você na sua conta de desenvolvedor Apple! Acesse sua conta por meio <a href="https://developer.apple.com/account" target="_blank">deste link</a> e verifique o aviso exibido na tela inicial.
                    </Typography>
                </div>
            </Card>
        )
    }

    return <div />;
}