import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Logo from '../../../assets/img/logo.svg'
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../../../hooks/authentication.hook';

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

export default function Header() {
    const classes = useStyles();
    const navigate = useNavigate();
    
    const { token } = useAuthentication()

    return (
        <AppBar position="absolute" color='white'>
            <Toolbar className={classes.toolbar}>
                <img src={Logo} alt="logo" />

                <Box>
                    <Tooltip arrow
                        title="Acesse o painel de controle e tenha acesso à diversas opções para o seu aplicativo."
                    >
                        <Button color="primary" onClick={() => navigate('/')} className='onboardingPreviewerPanelButton'>
                            Ir ao painel de controle
                        </Button>
                    </Tooltip>

                    <Tooltip arrow
                        title="Contrate o plano ideal para o seu negócio e publique o aplicativo para todos os seus clientes."
                    >
                        <Button
                            color="primary"
                            onClick={() => navigate('/pricing')}
                            sx={{ fontSize: 16 }}
                            variant='contained'
                        >
                            Publicar aplicativo
                        </Button>
                    </Tooltip>
                </Box>

            </Toolbar>
        </AppBar>
    );
}