import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import NotificationCard from './NotificationCard';
import _ from 'lodash';
import SaveButton from '../../../components/SaveButton';
import useAppInfo from '../../../hooks/appInfo.hook';
import DashboardLayout from '../../../layouts/Dashboard';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import { toast } from 'react-toastify';
import WarningCard from '../../../components/publish/WarningCard';
import { useUser } from '../../../contexts/user.context';
import InstructionsCard from './InstructionsCard';

export default function Sellflux() {
    const { appInfo } = useAppInfo();
    const { isWebviewTheme, user } = useUser();

    const [notifications, setNotifications] = useState([]);
    const [originalNotifications, setOriginalNotifications] = useState([]);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const addNewNotification = () => {
        let notificationsCopy = JSON.parse(JSON.stringify(notifications));
        notificationsCopy.push({
            id: Math.floor(Math.random() * 999999),
            tag: '',
            title: '',
            text: '',
            action: 'noAction'
        });
        setNotifications(notificationsCopy);
    }

    const deleteNotification = (index) => {
        let notificationsCopy = JSON.parse(JSON.stringify(notifications));
        _.pullAt(notificationsCopy, [index]);
        setNotifications(notificationsCopy);
    }

    const handleChange = (key, value, index) => {
        let notificationsCopy = JSON.parse(JSON.stringify(notifications));
        notificationsCopy[index][key] = value;

        if (key === 'action') {
            notificationsCopy[index]['link'] = '';
        }

        setNotifications(notificationsCopy);
    }

    const canBeSubmitted = () => {
        return !_.isEqual(originalNotifications, notifications);
    }

    const save = () => {
        setSaving(true);

        mobilizeiStoresService.setStoreFeatureById(
            appInfo.platformId,
            appInfo.storeId,
            'sellflux',
            { isActive: true, notifications }
        ).then(() => {
            toast.success('Alterações salvas com sucesso!');

            setOriginalNotifications(notifications);
        }).finally(() => {
            setSaving(false);
        })
    }

    useEffect(() => {
        mobilizeiStoresService.getStoreFeatureById(
            appInfo.platformId,
            appInfo.storeId,
            'sellflux'
        ).then(feature => {
            setNotifications(_.get(feature, 'notifications', []));
            setOriginalNotifications(_.get(feature, 'notifications', []));
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <DashboardLayout
            Icon={SchemaOutlinedIcon}
            title="Sellflux"
            subtitle="Configure as notificações que serão disparadas nos fluxos da Sellflux"
        >
            {loading ? (
                <Box display='flex' alignItems="center" justifyContent="center" height='100%'>
                    <CircularProgress />
                </Box>
            ) : (
                <Box flexDirection="column" flexGrow={1} display='flex' justifyContent='start' marginBottom={10}>
                    {isWebviewTheme && (
                        <WarningCard
                            marginY={2}
                            content={<>
                                O envio de notificações via Sellflux não é compatível com o tema Web. Selecione outro tema para poder usar esta função.
                            </>}
                        />
                    )}

                    <InstructionsCard />

                    <Box sx={{
                        paddingX: { sm: 8, xs: 0 },
                        paddingY: { sm: 4, xs: 2 }
                    }}>
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) =>
                                <NotificationCard
                                    deleteNotification={() => deleteNotification(index)}
                                    handleChange={handleChange}
                                    notification={notification}
                                    index={index}
                                    key={`not-${notification.id}`}
                                />
                            )
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1" component="h2" marginBottom={4}>
                                    Clique no botão abaixo para incluir a primeira notificação que estará disponível para disparo nos fluxos da Sellflux!
                                </Typography>
                            </div>
                        )}

                        <div style={{ textAlign: 'center' }}>
                            <Button variant="contained" onClick={() => addNewNotification()} >
                                <div className="px-16">Adicionar notificação</div>
                            </Button>
                        </div>

                        <SaveButton
                            show={canBeSubmitted()}
                            onCancel={() => setNotifications(originalNotifications)}
                            onClick={() => save()}
                            isLoading={saving}
                        />
                    </Box>

                </Box>
            )}
        </DashboardLayout >
    )
}