import { useEffect, useState } from "react";
import { useUser } from "../../contexts/user.context"
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/main";
import { useOnboarding } from "../../contexts/onboarding.context";
import Start from "./Start";
import StoreName from "./StoreName";
import StoreUrl from "./StoreUrl";
import EcommercePlatform from "./EcommercePlatform";
import EcommercePlatformName from "./EcommercePlatformName";
import StoreCartUrl from "./StoreCartUrl";
import StoreAccountUrl from "./StoreAccountUrl";
import { PLATFORMS, onboardingSteps } from "../../utils/constants";
import CreatingApp from "./CreatingApp";
import Previewer from "./Previewer";
import AppLoading from "../../components/AppLoading";
import EcommercePlatformIntegration from "./EcommercePlatformIntegration";

export default function Onboarding() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);

    const { currentStep, data, goToStep } = useOnboarding();

    useEffect(() => {
        if (user.isOnboardingCompleted) {
            navigate('/');
            return;
        }

        setIsLoading(false);
    }, [])

    useEffect(() => {
        if(data?.ecommercePlatformId === PLATFORMS.WIX){
            if (
                currentStep !== onboardingSteps.CREATING_APP &&
                data?.storeId &&
                data?.storeUrl &&
                data?.ecommercePlatformId &&
                data?.ecommercePlatformToken &&
                data?.ecommercePlatformRefreshToken
            ) {
                goToStep(onboardingSteps.CREATING_APP)
            }
        }else{
            if (
                currentStep !== onboardingSteps.CREATING_APP &&
                data?.storeId &&
                data?.storeUrl &&
                data?.ecommercePlatformId &&
                data?.ecommercePlatformToken
            ) {
                goToStep(onboardingSteps.CREATING_APP)
            }
        }
    }, [])

    const getStep = () => {
        switch (currentStep) {
            case onboardingSteps.START: return <Start />
            case onboardingSteps.STORE_NAME: return <StoreName />
            case onboardingSteps.ECOMMERCE_PLATFORM: return <EcommercePlatform />
            case onboardingSteps.ECOMMERCE_PLATFORM_INTEGRATION: return <EcommercePlatformIntegration />
            case onboardingSteps.STORE_URL: return <StoreUrl />
            case onboardingSteps.ECOMMERCE_PLATFORM_NAME: return <EcommercePlatformName />
            case onboardingSteps.STORE_CART_URL: return <StoreCartUrl />
            case onboardingSteps.STORE_ACCOUNT_URL: return <StoreAccountUrl />
            case onboardingSteps.CREATING_APP: return <CreatingApp />
            default: return <div />;
        }
    }

    // This is here to prevent flickering when
    // redirecting the user to the dashboard
    if (isLoading)
        return <AppLoading />

    if (currentStep === onboardingSteps.PREVIEWER) {
        return <Previewer />
    }

    return (
        <MainLayout>
            {getStep()}
        </MainLayout>
    )
}