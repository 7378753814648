import { Box, Button, Checkbox, Dialog, DialogContent, Typography } from '@mui/material';
import React, { useState } from 'react';

export default function CompanyAccountDialog(props) {
    const [isCompanyAccountChecked, setIsCompanyAccountChecked] = useState(false);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogContent>
                <Typography align="justify" variant="h5">
                    Atenção
                </Typography>
            </DialogContent>
            <DialogContent>
                <Typography align="justify" variant="subtitle1" sx={{ marginBottom: 2 }}>
                    Somente com uma conta de desenvolvedor Google Play do tipo <strong>"Organizacional"</strong>, será possível publicar a versão Android do seu aplicativo! Caso você possua outro tipo de conta, <strong>a publicação do seu aplicativo Android não poderá ser realizada.</strong>
                </Typography>

                <Box display='flex' alignItems='center' marginBottom={1} marginTop={4} sx={{ cursor: 'pointer' }} onClick={() => setIsCompanyAccountChecked(!isCompanyAccountChecked)}>
                    <Checkbox checked={isCompanyAccountChecked} />
                    <Typography align="justify" variant="subtitle1">
                        Possuo uma conta de desenvolvedor Google Play do tipo "Organizacional"
                    </Typography>
                </Box>

                <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                    <Button disabled={!isCompanyAccountChecked} variant="contained" onClick={() => props.goToNextStep()} >
                        próximo
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}