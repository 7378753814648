import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TaskCard({ Icon, link, text }) {
    const navigate = useNavigate();

    return (
        <Button 
            color='secondary' 
            elevation={0} 
            onClick={() => navigate(link)}
            variant="outlined" 
            sx={{
                height: 120,
                width: 120,
                margin: '8px',
                marginBottom: { sm: 0},
                marginRight: { sm: '16px'}
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: "100%",
                justifyContent: 'space-evenly',
                textTransform: 'none'
            }}>
                <Icon fontSize="small" sx={{ mb: 1 }} />
                <Typography align="left" variant="body2" >
                    {text}
                </Typography>
            </Box>
        </Button>
    )
}
