import React, { useState } from 'react';
import { Box } from '@mui/material';
import DashboardLayout from '../../../layouts/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import Subscription from './Subscription';
import AccountTabs from './AccountTabs';
import InvoicesHistory from './InvoicesHistory';
import General from './General';

export default function Account() {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <DashboardLayout
            Icon={PersonIcon}
            title="Minha conta"
        >
            <AccountTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

            <Box px={{ xs: 2, lg: 12 }} mt={5}>
                {selectedTab === 0 && <General />}
                {selectedTab === 1 && <Subscription />}
                {selectedTab === 2 && <InvoicesHistory />}
            </Box>
        </DashboardLayout>
    );
}