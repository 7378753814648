import React, { useState } from 'react';
import _ from 'lodash';
import UserData from './UserData';
import ChangePassword from './ChangePassword';
import { useUser } from '../../../../contexts/user.context';
import { useForm } from 'react-hook-form';
import SaveButton from '../../../../components/SaveButton';
import { mobilizeiUsersService } from '../../../../services/mobilizei/users.service';
import { showApiError } from '../../../../utils/errors';
import { toast } from 'react-toastify';

export default function General() {
    const { user, setUser } = useUser();

    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, watch, reset, register, formState: { errors, isDirty } } = useForm({
        defaultValues: {
            name: user?.name,
            email: user?.email,
            phoneNumber: user?.phoneNumber?.replace('+55', ''),
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
        }
    });

    const onSubmit = (data) => {
        if (!data) {
            return
        }

        setIsLoading(true)

        // Remove empty properties
        let dataToPost = _.omitBy(data, _.isEmpty);

        // Format phone number
        if (dataToPost.phoneNumber && !dataToPost.phoneNumber.includes('+55')) {
            dataToPost.phoneNumber = '+55' + dataToPost.phoneNumber;
        }

        mobilizeiUsersService.updateUserData(dataToPost).then(() => {
            toast.success('Alterações salvas com sucesso!');

            const newData = {
                name: data.name,
                email: data.email,
                phoneNumber: data.phoneNumber,
                currentPassword: '',
                password: '',
                passwordConfirmation: '',
            }

            // Update the local user data to match the data that was 
            // updated on the backend
            setUser({ ...user, ...newData });

            // Calling the reset function with an object will update the form defaultValues 
            // and subsequent calls to reset() will use the new default values
            reset(newData);
        })
            .catch(e => {
                showApiError(e.error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <>
            <UserData
                control={control}
                register={register}
                errors={errors}
            />
            <ChangePassword
                watch={watch}
                register={register}
                errors={errors}
            />
            <SaveButton
                isLoading={isLoading}
                onCancel={() => reset()}
                show={isDirty}
                onClick={handleSubmit(onSubmit)}
            />
        </>
    );
}