import { apiFetcher } from "./apiFetcher";

export const mobilizeiPushNotificationsService = {
    sendPushNotification
};

function sendPushNotification(platformId, storeId, data) {
    const url = `platforms/${platformId}/stores/${storeId}/push_notifications`

    return apiFetcher(url, {
        method: "POST",
        body: JSON.stringify(data)
    }).then(response => response);
}