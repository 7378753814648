import { apiFetcher } from "./apiFetcher";

export const mobilizeiStoresService = {
  getStoreById,
  updateStoreById,
  getStoreFeatures,
  getStoreFeatureById,
  setStoreFeatureById,
  getThemeById,
  setTheme,
  resetThemeById,
  setMobileAppPublishStatus,
  uploadFile
};

// ------------------
// ----- Stores -----
// ------------------

function getStoreById(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function updateStoreById(platformId, storeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}`

  return apiFetcher(url, {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(response => response);
}

// --------------------
// ----- Features -----
// --------------------

function getStoreFeatures(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}/features`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getStoreFeatureById(platformId, storeId, featureId) {
  const url = `platforms/${platformId}/stores/${storeId}/features/${featureId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function setStoreFeatureById(platformId, storeId, featureId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/features/${featureId}`

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

// ------------------
// ----- Themes -----
// ------------------

function getThemeById(platformId, storeId, themeId) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function setTheme(platformId, storeId, themeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`

  return apiFetcher(url, {
      method: "POST",
      body: JSON.stringify(data)
  }).then(response => response);
}

function resetThemeById(platformId, storeId, themeId) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}/reset`

  return apiFetcher(url, {
      method: "POST"
  }).then(response => response);
}

// --------------------------
// ----- Publish Status -----
// --------------------------

function setMobileAppPublishStatus(platformId, storeId, mobileAppPlatform, publishStatus) {
  const url = `platforms/${platformId}/stores/${storeId}/publish_status`

  const data = {
    platform: mobileAppPlatform,
    publishStatus,
  }

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

// -----------------
// ----- Other -----
// -----------------

function uploadFile(platformId, storeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/upload_file`

  return apiFetcher(url, {
    method: "POST",
    body: data
  }, false).then(response => response);
}
