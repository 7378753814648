import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from "@mui/material";
import _ from 'lodash';
import useAppInfo from '../../../../hooks/appInfo.hook';

const useStyles = makeStyles({
    appName: {
        color: '#555555',
        textTransform: 'uppercase',
        fontSize: 14
    },
    time: {
        color: '#555555',
        fontSize: 14
    },
    close: {
        transform: 'scale(1.3, 1)',
        fontWeight: 400
    },
    body: {
        overflowY: 'scroll'
    },
    content: {
        padding: '0px 10px 10px 10px',
        display: 'flex',
        WebkitBoxPack: 'justify',
        justifyContent: 'space-between',
        lineHeight: '16px',
        maxHeight: 215,
        textOverflow: 'ellipsis',
        boxSizing: 'content-box'
    },
    textContainer: {
        lineHeight: '19px',
        maxWidth: 300,
        overflowX: 'hidden'
    }
});

export default function PushiOS({ payload }) {
    const { appInfo } = useAppInfo()

    const [expanded, setExpanded] = useState(false);
    const imageUrl = _.get(payload, 'image.url', null);
    const canExpand = payload.text.length > 45 || !_.isEmpty(imageUrl) || payload.text.includes('\n');

    const classes = useStyles({ canExpand, expanded, iconUrl: appInfo.iconUrl, imageUrl });

    useEffect(() => {
        if (payload.text.length <= 45 && _.isEmpty(imageUrl)) {
            setExpanded(false);
        }
    }, [payload]);

    return (
        <Card
            onClick={() => canExpand && setExpanded(!expanded)}
            sx={{
                borderRadius: '10px',
                cursor: canExpand ? 'pointer' : 'auto',
                fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
                marginTop: expanded ? '125px' : '255px',
                padding: 0,
                position: 'absolute',
                marginLeft: '30px',
                width: '340px'
            }}
        >
            <Box sx={{
                padding: '10px 10px 8px 10px',
                borderBottom: expanded ? '1px #ededed solid' : 'none',
                display: 'flex',
                WebkitBoxPack: 'justify',
                fontWeight: '300',
                justifyContent: 'space-between',
                lineHeight: '20px',
                marginBottom: '5px',
                textAlign: 'center'
            }}>
                <Box display='flex'>
                    <Box sx={{
                        backgroundImage: _.isEmpty(appInfo.iconUrl) ?
                            `url(${require('../../../../assets/img/push/apple_icon.svg').default})` :
                            `url("${appInfo.iconUrl}")`,
                        backgroundSize: '19px',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '3px',
                        height: '19px',
                        marginRight: '5px',
                        width: '19px',
                    }} />
                    <span className={classes.appName}>{_.isEmpty(appInfo.title) ? 'Meu aplicativo' : appInfo.title}</span>
                </Box>
                {expanded ?
                    <span className={classes.close}>X</span> :
                    <span className={classes.time}>agora</span>
                }
            </Box>

            <div className={classes.body}>
                {expanded && imageUrl &&
                    <img className={classes.image} src={imageUrl} />
                }

                <div className={classes.content}>
                    <div className={classes.textContainer}>
                        {!_.isEmpty(payload.title) &&
                            <Typography sx={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: 500,
                                display: 'inline-block',
                                overflow: 'hidden',
                                width: _.isEmpty(imageUrl) ? '320px' : '280px',
                                textOverflow: 'ellipsis',
                                minHeight: 0,
                                minWidth: 0,
                                whiteSpace: 'nowrap'
                            }}>
                                {payload.title}
                            </Typography>
                        }
                        <Typography sx={{
                            margin: 0,
                            fontSize: '15px',
                            fontWeight: '300',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            paddingBottom: 0,
                            width: _.isEmpty(imageUrl) ? '320px' : '280px',
                            textOverflow: 'ellipsis',
                            maxHeight: expanded ? '999px' : '75px',
                            minHeight: 0,
                            minWidth: 0,
                            WebkitLineClamp: expanded ? '999' : '4',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'pre-line'
                        }}>
                            {_.isEmpty(payload.text) ? 'Esse é o texto da minha notificação' : payload.text}
                        </Typography>
                    </div>

                    {!expanded && imageUrl &&
                        <Box component='span' sx={{
                            alignSelf: 'flex-start',
                            backgroundImage: `url("${imageUrl}")`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            width: '35px',
                            height: '35px'
                        }} />
                    }
                </div>
            </div>
        </Card>
    )
}
