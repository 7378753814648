import React from 'react';
import { Box, Card, CardContent, FormControl, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import _ from "lodash";
import { MbTextField } from '../../../components/MbTextFields';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
    loadingContainer: {
        height: '100%'
    },
    card: {
        boxShadow: 'none',
        marginBottom: 32
    },
    themeCard: {
        padding: 0
    },
    label: {
        display: "block",
        margin: "0",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    },
});

export default function NotificationCard({ notification, deleteNotification, handleChange, index }) {
    const classes = useStyles();

    return (
        <Box marginBottom={4}>
            <Card elevation={4}>
                <CardContent>
                    <Box flexDirection="row" display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography className="h2" display='inline'>
                            NOTIFICAÇÃO #{index + 1}
                        </Typography>
                        <IconButton onClick={() => deleteNotification()}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>

                    <Grid
                        container
                        spacing={2}
                        flexDirection={'row'}
                    >
                        <Grid item xs={12}>
                            <MbTextField
                                helper={`Caracteres restantes: ${60 - notification.title.length}`}
                                id="title"
                                inputProps={{ maxLength: 60 }}
                                label="Título (opcional)"
                                name="title"
                                onChange={(e) => handleChange('title', e.target.value, index)}
                                value={notification.title}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MbTextField
                                helper={`Caracteres restantes: ${400 - notification.text.length}`}
                                id="push"
                                inputProps={{ maxLength: 400 }}
                                label="Mensagem"
                                name="push"
                                onChange={(e) => handleChange('text', e.target.value, index)}
                                rows={5}
                                value={notification.text}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant='outlined' fullWidth>
                                <label className={classes.label}>Enviar a notificação quanto tempo depois de o carrinho ter sido abandonado?</label>
                                <Select
                                    fullWidth
                                    value={notification.delayInMinutes}
                                    onChange={(e) => handleChange('delayInMinutes', e.target.value, index)}
                                    displayEmpty
                                    variant='outlined'
                                >
                                    <MenuItem value={'15'}>15 minutos</MenuItem>
                                    <MenuItem value={'30'}>30 minutos</MenuItem>
                                    <MenuItem value={'60'}>1 hora</MenuItem>
                                    <MenuItem value={'120'}>2 horas</MenuItem>
                                    <MenuItem value={'240'}>4 horas</MenuItem>
                                    <MenuItem value={'360'}>6 horas</MenuItem>
                                    <MenuItem value={'720'}>12 horas</MenuItem>
                                    <MenuItem value={'1440'}>1 dia</MenuItem>
                                    <MenuItem value={'2880'}>2 dias</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}