import * as React from 'react'
import { planIds } from '../utils/constants';

const PlanContext = React.createContext()

function usePlan() {
    const context = React.useContext(PlanContext);
    if (!context) {
        throw new Error(`usePlan must be used within a PlanContext`);
    }
    return context;
}

function PlanProvider(props) {
  const [plan, setPlan] = React.useState(planIds.PLAN_BASIC_MONTHLY_2023);

  const clearPlanContext = () => {
    setPlan(planIds.PLAN_BASIC_MONTHLY_2023)
  }

  return <PlanContext.Provider value={{plan, setPlan, clearPlanContext}} {...props} />
}

export { PlanContext, PlanProvider, usePlan }