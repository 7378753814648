import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    card: {
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        minHeight: 45,
        marginTop: 40,
        textAlign: 'center',
        width: 'fit-content'
    },
    cardContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        padding: '15px 10px'
    },
    question: {
        alignItems: 'center',
        borderRight: 'solid 1px rgba(0, 0, 0, 0.23)',
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        marginRight: 10,
        paddingRight: 10

    }
}))

export default function HelpCard({ link, linkText }) {
    const classes = useStyles();

    return (
        <div variant="outlined" className={classes.card}>
            <div className={classes.cardContent}>
                <div className={classes.question}>
                    <HelpOutlineIcon sx={{ marginRight: 1 }} />
                    <Typography align="left" variant="body2">
                        Dúvidas?
                    </Typography>
                </div>
                <a href={link} target="_blank">
                    <Typography align="left" variant="body2">
                        {linkText}
                    </Typography>
                </a>
            </div>
        </div>
    )
}