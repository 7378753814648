import { apiFetcher } from "./apiFetcher";

export const mobilizeiSubscriptionsService = {
    create,
    getById,
    suspend,
    activate,
    updateBillingInformation,
    createPaymentMethod,
    removePaymentMethod,
    getInvoices,
    getInvoiceById
};

function create(data) {
    return apiFetcher(
        'subscriptions',
        {
            method: "POST",
            body: JSON.stringify(data)
        }
    );
}

function getById(subscriptionId) {
    const url = `subscriptions/${subscriptionId}`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}

function suspend(subscriptionId) {
    const url = `subscriptions/${subscriptionId}`

    return apiFetcher(url, {
        method: "DELETE"
    }).then(response => response);
}

function activate(subscriptionId) {
    const url = `subscriptions/${subscriptionId}/activate`

    return apiFetcher(url, {
        method: "POST"
    }).then(response => response);
}

function updateBillingInformation(subscriptionId, data) {
    return apiFetcher(
        `subscriptions/${subscriptionId}/billing_information`,
        {
            method: "PUT",
            body: JSON.stringify(data)
        }
    );
}

function createPaymentMethod(subscriptionId, data) {
    const url = `subscriptions/${subscriptionId}/payment_method`

    return apiFetcher(url, {
        method: "POST",
        body: JSON.stringify(data)
    }).then(response => response);
}

function removePaymentMethod(subscriptionId) {
    const url = `subscriptions/${subscriptionId}/payment_method`

    return apiFetcher(url, {
        method: "DELETE"
    }).then(response => response);
}

function getInvoices(subscriptionId) {
    const url = `subscriptions/${subscriptionId}/invoices`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}

function getInvoiceById(subscriptionId, invoiceId) {
    const url = `subscriptions/${subscriptionId}/invoices/${invoiceId}`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}