import React from 'react';
import { Box, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    card: {
        alignItems: 'center',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        display: 'flex',
        marginBottom: 10,
        minHeight: 90,
        padding: 15
    },
    cardContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        lineHeight: 1.8
    }
}))

export default function ActionBanner({ action, icon, text }) {
    const classes = useStyles();

    return (
        <div className={classes.card}>
            <div className={classes.cardContent}>
                {icon}
                <Box marginLeft={2}>
                    <Typography align="left" variant="body2" >
                        {text}
                    </Typography>
                    {action}
                </Box>
            </div>
        </div>
    )
}