import React from 'react';
import _ from 'lodash';
import { Typography, Link, Box } from "@mui/material";
import useAppInfo from '../../../hooks/appInfo.hook';
import { useUser } from "../../../contexts/user.context";
import Warnings from "../../../components/publish/Warnings";
import MissingDataCard from "../../../components/publish/MissingDataCard";
import PublishedCard from "../../../components/publish/PublishedCard";
import PublishingCard from "../../../components/publish/PublishingCard";
import WarningCard from "../../../components/publish/WarningCard";
import PublishButton from "../../../components/publish/PublishButton";

export default function Step2(props) {
    const { appInfo, isAppBeingPublished } = useAppInfo();
    const { user } = useUser();

    const publishStatus = appInfo.iOSPublishStatus;
    const downloadUrl = appInfo.iOSDownloadUrl;

    function isMissingData() {
        return (
            _.isEmpty(user?.store?.title) ||
            _.isEmpty(user?.store?.shortDescription) ||
            _.isEmpty(user?.store?.description) ||
            _.isEmpty(user?.store?.privacyPolicyUrl) ||
            _.isEmpty(user?.store?.keywords) ||
            _.isEmpty(user?.store?.launchScreenUrl) ||
            _.isEmpty(user?.store?.iconUrl) ||
            _.isEmpty(user?.store?.featureGraphicUrl)
        )
    }

    return (
        <Box sx={{ paddingX: 2, paddingY: 4 }}>
            {_.isEmpty(downloadUrl) && (
                <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                    Fez todas as configurações necessárias e está pronto para disponibilizar a versão iOS do seu aplicativo para os seus clientes? Clique no botão “publicar” para enviar o aplicativo para o processo de aprovação da App Store.
                </Typography>
            )}

            {publishStatus !== 'first_publish_pending' && !_.isEmpty(downloadUrl) && (
                <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                    Seu aplicativo iOS já está publicado e disponível para download <Link target="_blank" href={downloadUrl} >neste link</Link>. Alterou algum dado que exige a publicação de uma atualização? Clique no botão “publicar” para enviar o seu aplicativo para o processo de aprovação da App Store.
                </Typography>
            )}

            <Typography align="justify" variant="subtitle1" sx={{ mb: 3 }}>
                Caso você ainda não tenha criado a sua conta de desenvolver Apple ou gostaria de rever algum tutorial, <Link className="cursor-pointer" onClick={props.goToFirstStep}>clique aqui</Link> para voltar ao início do processo de publicação do seu aplicativo iOS.
            </Typography>

            <Box sx={{ px: { lg: 14 } }}>
                <Warnings deadlineInDays={20} downloadUrl={downloadUrl} platformName="iOS" publishStatus={publishStatus} />

                {isAppBeingPublished('ios') && (
                    <PublishingCard
                        lastPublishRequestedAt={appInfo.iOSLastPublishRequestedAt}
                        text="Seu aplicativo iOS está em aprovação e deve levar cerca de 15 dias úteis para ser liberado. Este prazo pode variar para mais ou para menos de acordo com a velocidade da revisão realizada pela Apple."
                    />
                )}

                {!isAppBeingPublished('ios') && isMissingData() &&
                    <MissingDataCard />
                }

                {(publishStatus == 'first_publish_pending' || publishStatus == 'pending_update') && !isMissingData() &&
                    <PublishButton platform="ios" text="Tenho uma conta de desenvolvedor Apple do tipo empresarial ativa, concedi um acesso ao email suporte@mobilizei.com.br e estou ciente do prazo de publicação e dos demais avisos acima." />
                }

                {publishStatus == 'published' && !isMissingData() &&
                    <PublishedCard platform="iOS" />
                }

                {publishStatus == 'developer_account_pending' &&
                    <WarningCard
                        content={<>
                            Você precisa criar a sua conta de desenvolvedor Apple empresarial para seguir com a publicação do seu aplicativo iOS!{' '}
                            <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-apple" target="_blank">Clique aqui</a>{' '}
                            e veja como criar esta conta.
                        </>}
                    />
                }

                {publishStatus == 'pending_apple_account_renewal' &&
                    <WarningCard
                        content={<>
                            A assinatura da sua conta de desenvolvedor iOS expirou e com isso <b>a Apple tirou seu app do ar!</b> Para disponibilizar o seu aplicativo iOS para download novamente, você deve acessar a sua conta Apple por meio <a href="https://developer.apple.com/account" target="_blank">deste link</a> e efetuar a renovação da sua assinatura.
                        </>}
                    />
                }

                {publishStatus == 'pending_apple_account_terms_agreement' &&
                    <WarningCard
                        content={<>
                            Existe uma atualização de contrato que deve ser aceita por você na sua conta de desenvolvedor Apple! Acesse sua conta por meio <a href="https://developer.apple.com/account" target="_blank">deste link</a> e verifique o aviso exibido na tela inicial.
                        </>}
                    />
                }
            </Box>
        </Box>
    )
}