import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import TabBar from './TabBar';
import StatusBar from './StatusBar';
import QrCode from '../QrCode';
import { useOnboarding } from '../../../../contexts/onboarding.context';
import { Box, Hidden, LinearProgress } from '@mui/material';
import TabBarColors from '../TabBarColors';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    phoneHeader: {
        position: 'absolute',
        top: -11,
        width: '80%',
        zIndex: 99,
    },
    loadingWrapper: {
        color: '#1a76d3',
        position: 'absolute',
        width: '100%'
    },
    iframeWrapper: {
        alignItems: 'center',
        borderBottom: '20px solid #000',
        borderLeft: '8px solid #000',
        borderRight: '8px solid #000',
        borderTop: '13px solid #000',
        borderRadius: '15px',
        boxShadow: '0 -1px 8px 2px rgba(0,0,0,.08), 0 1px 8px 2px rgba(0,0,0,.08), 2px 0 8px 2px rgba(0,0,0,.08), -2px 0 8px 2px rgba(0,0,0,.08)',
        display: 'flex',
        flexDirection: 'column',
        height: '670px',
        justifyContent: 'center',
        marginTop: '35px',
        position: 'relative',
        width: '345px',
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            margin: 0,
            border: 0,
            borderRadius: 0,
            height: 'calc(100vh - 64px)',
            marginTop: 64
        }
    },
    iframe: {
        border: 0,
        flexGrow: 1,
        width: '100%'
    },
    tabBarColors: {
        position: 'absolute',
        bottom: 0,
        right: 420
    },
    qrcode: {
        position: 'absolute',
        bottom: 0,
        left: 420
    }
}));

export default function Smartphone() {
    const classes = useStyles();

    const [isLoadingUrl, setIsLoadingUrl] = useState(true);
    const [url, setUrl] = useState();

    const [tabBarData, setTabBarData] = useState();
    const { theme } = useOnboarding();

    useEffect(() => {
        const data = theme?.settings?.find(s => s.key === 'tabBar');
        setTabBarData(data);
        setUrl(data?.content[0]?.url);
    }, [theme])

    const handleTabBarButtonClick = data => {
        if (data.action == 'goToWebview') {
            setUrl(data.url);
            setIsLoadingUrl(true);
        } else if (data.action == 'goToWhatsapp') {
            window.open(`https://wa.me/55${data.areaCode}${data.phoneNumber}`)
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Box className={clsx(classes.iframeWrapper, 'onboardingPreviewerSmartphone')}>
                <Hidden mdDown>
                    <img
                        alt="phone header"
                        src={require('../../../../assets/img/phone_header.png')}
                        className={classes.phoneHeader}
                    />
                </Hidden>

                <Box sx={{
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>
                    <StatusBar />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        height: '100%'
                    }}>
                        {isLoadingUrl &&
                            <div className={classes.loadingWrapper}>
                                <LinearProgress color='inherit' />
                            </div>
                        }

                        <iframe
                            className={classes.iframe}
                            onLoad={() => setIsLoadingUrl(false)}
                            src={url}
                        />

                        <TabBar data={tabBarData} onButtonClick={(data) => handleTabBarButtonClick(data)} />
                    </Box>
                </Box>
            </Box>

            <Hidden mdDown>
                <div className={classes.tabBarColors}>
                    <TabBarColors />
                </div>

                <div className={classes.qrcode}>
                    <QrCode />
                </div>
            </Hidden>
        </div >
    );
}
