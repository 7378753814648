import { Box, Button, CircularProgress, Fade, Paper, Typography } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function SaveButton({ isLoading, onCancel, onClick, show }) {
    return (
        <Fade in={show}>
            <Paper
                elevation={6}
                sx={{
                    alignItems: 'center',
                    borderRadius: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    height: { xs: '120px', md: '80px' },
                    justifyContent: 'space-between',
                    padding: { xs: 2, md: 4 },
                    position: 'absolute',
                    right: 0,
                    width: { xs: '100%', lg: 'calc(100% - 280px)' }
                }}
            >
                <Box display='flex' alignItems='center'>
                    <WarningAmberIcon sx={{ mr: 1 }} />
                    <Typography variant="body2">
                        Deseja salvar as alterações realizadas?
                    </Typography>
                </Box>

                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Box>
                        <Button
                            onClick={onCancel}
                        >
                            Descartar alterações
                        </Button>

                        {onClick ? (
                            <Button
                                onClick={onClick}
                                variant="contained"
                            >
                                Salvar
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                variant="contained"
                            >
                                Salvar
                            </Button>
                        )}
                    </Box>
                )}
            </Paper>

        </Fade>
    )
}
