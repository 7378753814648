import React, { useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import useAppInfo from '../../hooks/appInfo.hook';
import { useUser } from "../../contexts/user.context";
import { mobileAppPlatforms } from '../../utils/constants';
import { mobilizeiStoresService } from '../../services/mobilizei/stores.service';
import _ from 'lodash';
import { showApiError } from '../../utils/errors';

export default function MissingDataCard({ platform, text }) {
    const { isAndroidAppPublished, isiOSAppPublished } = useAppInfo();
    const { user, setUser } = useUser();

    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);

    function publish() {
        setIsPublishing(true);

        let publishStatus = '';
        if (platform === mobileAppPlatforms.ANDROID) {
            publishStatus = isAndroidAppPublished() ? 'publishing' : 'pending_listing_creation';
        } else {
            publishStatus = isiOSAppPublished() ? 'publishing' : 'pending_listing_creation';
        }

        mobilizeiStoresService.setMobileAppPublishStatus(
            user?.store?.platformId,
            user?.store?.id,
            platform,
            publishStatus
        )
            .then(() => {
                let data = {}
                _.set(data, `platforms.${platform}.publishStatus`, publishStatus);
                _.set(data, `platforms.${platform}.publishStatusUpdatedAt`, new Date());
                _.set(data, `platforms.${platform}.lastPublishRequestedAt`, new Date());

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({ ...user, store: _.merge(user.store, data) });
            })
            .catch(e => {
                console.log('errro on publish', e)

                showApiError(e.error);
            })
            .finally(() => {
                setIsPublishing(false);
            })
    }

    return (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' marginTop={8}>
            {isPublishing ? <CircularProgress /> :
                <>
                    <Box display='flex' alignItems='center' marginBottom={1} sx={{ cursor: 'pointer' }} onClick={() => setIsAgreementChecked(!isAgreementChecked)}>
                        <Checkbox checked={isAgreementChecked} />
                        <Typography align="justify" variant="subtitle1">
                            {text}
                        </Typography>
                    </Box>

                    <Button disabled={!isAgreementChecked} variant="contained" onClick={publish}>
                        publicar
                    </Button>
                </>
            }
        </Box>
    )
}