import { apiFetcher } from "./apiFetcher";
import _ from "lodash";

export const productsService = {
  getProducts,
  getProductById,
};

function getProducts(storeId, query, accessToken) {
  const url = `stores/v1/products/query`

  let body = {
    query: query.filters,
    includeVariants: true
  }

  if(!query.filters.paging?.limit){
    body.query.paging = {
      ...body.query.paging,
      limit: 30
    }
  }

  return apiFetcher(
    storeId,
    accessToken,
    url,
    { method: "POST", body: JSON.stringify(body) },
    1,
    true
  );
}

function getProductById(storeId, productId, accessToken) {
  const url = `stores/v1/products/${productId}`
  
  return apiFetcher(storeId, accessToken, url, {
    method: "GET"
  })
}