import * as React from 'react';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import useAppInfo from '../../../hooks/appInfo.hook';
import { baseDeeplink } from '../../../utils/constants';

export default function WelcomeDialog({ isOpen, setIsOpen }) {
    const { appInfo } = useAppInfo();
    const appLink = `${baseDeeplink}&deep_link_value=${appInfo.platformId}&deep_link_sub1=${appInfo.storeId}`;

    return (
        <Dialog open={isOpen}>
            <Grid container justifyContent='center' sx={{ padding: 3 }}>
                <Typography component="h1" variant="h5" align='center'>
                    Seu aplicativo está pronto!
                </Typography>
                <Typography sx={{ my: 2 }} component="h2" variant="body1" align='justify'>
                    Acesse <a href={appLink} target='blank'>esse link</a> no seu 
                    celular ou então escaneie o QR Code abaixo para ver o app da 
                    sua loja diretamente no celular.
                </Typography>

                <QRCode value={appLink} size={200} />

                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='justify'>
                    Depois é só iniciar sua assinatura e disponibilizar seu app para todos os seus clientes!
                </Typography>
                <Grid item xs={8} sm={6}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => setIsOpen(false)}
                    >
                        Acessar painel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}