import React, { useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import useAppInfo from '../../../hooks/appInfo.hook';
import { makeStyles } from '@mui/styles';
import { MbTextField } from '../../../components/MbTextFields';
import MbTextArea from "../../../components/MbTextArea";
import WarningCard from '../../../components/publish/WarningCard';
import ProductPicker from '../../../components/ProductPicker';
import CategoryPicker from '../../../components/CategoryPicker';
import ImageUploaderBlock from '../../../components/ImageUploaderBlock';
import { useUser } from '../../../contexts/user.context';

const useStyles = makeStyles(() => ({
    label: {
        display: "block",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    }
}))

export default function PushForm({ isSending, onPayloadChange, onSend, payload, showSendButton }) {
    const { appInfo, isAppPublished } = useAppInfo()
    const { isBasic, isWebviewTheme } = useUser();

    const [showPushModal, setShowPushModal] = useState(false);

    const classes = useStyles();

    const handleUploadSuccess = (uploadData) => {
        onPayloadChange('image', uploadData);
    }

    const handleShowPushModalChange = () => {
        onPayloadChange('showPushModal', !showPushModal);
        setShowPushModal(!showPushModal);
    }

    return (
        <Grid
            container
            component="form"
            spacing={2}
            direction={'row'}
            sx={{ mt: 2 }}
            onSubmit={onSend}
        >
            <Grid item xs={12}>
                <MbTextField
                    helper={`Caracteres restantes: ${60 - payload.title.length}`}
                    label="Título (opcional)"
                    maxLength="60"
                    onChange={(e) => onPayloadChange('title', e.target.value)}
                    value={payload.title}
                />
            </Grid>

            <Grid item xs={12}>
                <MbTextArea
                    helper={`Caracteres restantes: ${400 - payload.text.length}`}
                    label="Mensagem"
                    maxLength="400"
                    onChange={(e) => onPayloadChange('text', e.target.value)}
                    rows="4"
                    value={payload.text}
                />
            </Grid>

            <Grid item xs={12}>
                <ImageUploaderBlock
                    label='Imagem (opcional)'
                    onDeleteSuccess={() => onPayloadChange('image', {})}
                    onUploadSuccess={handleUploadSuccess}
                    helperText={<>Tamanho máximo: 100 KB<br />Resolução recomendada: 300 x 300 px</>}
                    maxFileSizeInKB={100}
                    imageUrl={payload?.image?.url}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <label className={classes.label}>Ação</label>
                    <Select
                        fullWidth
                        value={payload.action}
                        onChange={(e) => onPayloadChange('action', e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value={'noAction'}>Abrir o aplicativo</MenuItem>
                        {!isBasic && !isWebviewTheme && (
                            <MenuItem value={'goToProduct'}>Abrir o aplicativo na tela de um produto</MenuItem>
                        )}
                        {!isBasic && !isWebviewTheme && (
                            <MenuItem value={'goToCategory'}>Abrir o aplicativo em uma categoria de produtos</MenuItem>
                        )}
                        <MenuItem value={'goToUrl'}>Abrir um endereço no navegador do celular</MenuItem>
                    </Select>
                    <Typography variant="caption">Selecione a ação que irá acontecer ao clicar na notificação</Typography>
                </FormControl>
            </Grid>

            {payload.action === 'goToProduct' &&
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <ProductPicker
                            selectedProductId={payload.link || ''}
                            onPicked={(p) => onPayloadChange('link', String(p.id))}
                        />
                        <Typography variant="caption">
                            Selecione o produto que será exibido ao clicar na notificação
                        </Typography>
                    </FormControl>
                </Grid>
            }

            {payload.action === 'goToCategory' &&
                <Grid item xs={12}>
                    <FormControl fullWidth className="mt-16 mb-16">
                        <CategoryPicker
                            selectedCategoryId={payload.link || ''}
                            onPicked={(c) => onPayloadChange('link', String(c.id))}
                        />
                        <Typography variant="caption">Selecione a categoria que será exibida ao clicar na notificação</Typography>
                    </FormControl>
                </Grid>
            }

            {payload.action === 'goToUrl' &&
                <Grid item xs={12}>

                    <FormControl fullWidth>
                        <MbTextField
                            label="Link"
                            value={payload.link || ''}
                            onChange={(e) => onPayloadChange('link', String(e.target.value))}
                        />
                        <Typography variant="caption">
                            Insira o link que será aberto no navegador do celular ao
                            clicar na notificação. Ex.: https://www.minhaloja.com.br/promocao
                        </Typography>
                    </FormControl>
                </Grid>
            }

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showPushModal || payload.showPushModal}
                                name="showPushModal"
                                onChange={() => handleShowPushModalChange()}
                            />
                        }
                        label="Exibir notificação push dentro do aplicativo"
                    />
                    <Typography variant="caption">
                        Marcando esta opção, ao clicar na notificação, o seu conteúdo
                        será exibido dentro de uma janela assim que o aplicativo for aberto
                    </Typography>
                </FormControl>
            </Grid>

            {showSendButton &&
                <Grid item xs={12}>
                    {isAppPublished() ? (
                        <Box display='flex' justifyContent='center' marginY={2}>
                            {isSending ? <CircularProgress /> :
                                <Button
                                    disabled={payload?.text?.length === 0}
                                    onClick={() => onSend()}
                                    variant="contained"
                                >
                                    enviar
                                </Button>
                            }
                        </Box>
                    ) : (
                        <WarningCard content="Seu aplicativo deve estar disponível para 
                        download para que seja possível enviar uma notificação push."
                        />
                    )}
                </Grid>
            }
        </Grid>
    )
}
