import React, { useState } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import CreditCardDataForm from '../../../../components/billing/CreditCardDataForm';
import { mobilizeiSubscriptionsService } from '../../../../services/mobilizei/subscriptions.service';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useUser } from '../../../../contexts/user.context';
import { useForm } from 'react-hook-form';

export default function CreditCardFormDialog({ isOpen, setIsOpen }) {
    const [loading, setLoading] = useState(false);
    const { user, setUser } = useUser()
    const { getValues, setValue, watch } = useForm({
        defaultValues: {
            creditCard: { }
        }
    });

    const submit = (creditCardToken) => {
        setLoading(true);

        let data = getValues();

        const cardNumberFormatted = `XXXX-XXXX-XXXX-${
            data?.creditCard?.number.slice(
            data?.creditCard?.number.length-4, 
            data?.creditCard?.number.length
        )}`

        const newCard = {
            displayNumber: cardNumberFormatted,
            holderName: data?.creditCard?.name.toUpperCase(),
            month: data?.creditCard?.expiration.split('/')[0],
            year: data?.creditCard?.expiration.split('/')[1]
        }

        mobilizeiSubscriptionsService.createPaymentMethod(user.subscription.id, { creditCardToken })
            .then(() => {
                toast.success('Cartão de crédito cadastrado com sucesso!');
                setIsOpen(false)    
                
                setUser({ ...user, subscription: { 
                    ...user?.subscription, 
                    creditCardData: newCard
                }});

            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível cadastrar o seu cartão de crédito. Por favor, confira os seus dados e tente novamente. (#${errorCode})`);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Dialog open={isOpen}>
            <Box py={2} px={4}>
                <Typography variant="h5" mb={2}>
                    Cadastrar cartão de crédito
                </Typography>

                <CreditCardDataForm
                    watch={watch}
                    disclaimer={<>Ao clicar no botão "Cadastrar cartão", você concorda com os nossos <a href="https://mobilizei.com.br/termos-de-uso" target="_blank" rel="noreferrer">Termos de Uso</a> e a nossa <a href="https://mobilizei.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a>. O valor do plano contratado será cobrado automaticamente no seu cartão de crédito na data de vencimento da assinatura. Você poderá cancelar a sua assinatura a qualquer momento para evitar cobranças futuras.</>}
                    loading={loading}
                    primaryButtonAction={submit}
                    primaryButtonLabel={'Cadastrar cartão'}
                    secondaryButtonAction={() => setIsOpen(false)}
                    secondaryButtonLabel={'Cancelar'}
                    setValue={setValue}
                    setLoading={setLoading}
                />
            </Box>
        </Dialog>
    );
}