import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import BillingDataForm from '../../../components/BillingDataForm';
import { useNavigate } from 'react-router';

export default function Step1({ control, register, errors, watch, setValue, goToStep2 }) {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Box mb={6}>
                <Typography variant="button" display="block">
                    PASSO <b>1</b> DE <b>2</b>
                </Typography>
                <Typography variant="h4">
                    Insira seus dados pessoais
                </Typography>
            </Box>

            <BillingDataForm 
                control={control}
                errors={errors}
                watch={watch}
                register={register}
                setValue={setValue}
                primaryButtonAction={goToStep2} 
                primaryButtonLabel={'Próximo'} 
                secondaryButtonAction={() => navigate('/pricing')} 
                secondaryButtonLabel={'Voltar'} 
                primaryButtonId={'hire_select_payment_method'}
            />
        </Container>
    );
}