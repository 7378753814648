import { toast } from 'react-toastify';

export const showApiError = error => {
    if (!error) {
        return toast.error('Não foi possível processar sua requisição.');
    }

    if (error.message) {
        return toast.error(error.message);
    }

    if (Array.isArray(error.messages)) {
        if (error.messages.length === 1)
            return toast.error(error.messages[0]);

        const messages = error.messages.map(m => <li>{m}</li>);
        return toast.error(
            <ul>{messages}</ul>
        );
    }
}