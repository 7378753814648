import axios from "axios";
import _ from 'lodash';
import { mobilizeiUsersService } from "../mobilizei/users.service";
import config from "../../utils/config";

export const authWixService = {
  refreshAccessToken
};

function refreshAccessToken(storeId) {
    return axios
        .post(
            `${config.MOBILIZEI_API_BASE_URL}/platforms/wix/auth/refresh`,
            { storeId }
        )
        .then(() => {
            return mobilizeiUsersService.getSelf().then(userData => {
                return _.get(userData, 'store.accessToken', '');
            })
        })
}