import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { baseDeeplink } from '../../../utils/constants';
import { useUser } from '../../../contexts/user.context';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    wrapper: {
        background: '#fff',
        borderRadius: 5,
        boxShadow: '0 -1px 8px 2px rgba(0,0,0,.08), 0 1px 8px 2px rgba(0,0,0,.08), 2px 0 8px 2px rgba(0,0,0,.08), -2px 0 8px 2px rgba(0,0,0,.08)',
        width: 220
    },
    title: {
        padding: '20px 20px 0 20px'
    },
    body: {
        borderTop: '1px solid #cecece',
        padding: '15px 20px 20px 20px'
    }
}));

export default function QrCode() {
    const classes = useStyles();

    const { user } = useUser();

    return (
        <Stack spacing={3} alignItems="center" className={clsx(classes.wrapper, 'onboardingPreviewerQrCode')}>

            <Typography variant="body1" align='center' className={classes.title} fontWeight={'bold'}>
                Escaneie para ver o aplicativo no seu celular
            </Typography>

            <QRCode value={`${baseDeeplink}&deep_link_value=${user.store?.platformId}&deep_link_sub1=${user.store?.id}`} size={100} />

            <Typography variant="body2" align='left' className={classes.body}>
                Com o QR Code acima você irá baixar o aplicativo
                visualizador da Mobilizei e poderá ver seu aplicativo
                no celular.{' '}
                <a 
                    target='_blank' 
                    href="https://mobilizei.com.br/tutoriais/como-ver-aplicativo-sua-loja-celular"
                >
                    Saiba mais.
                </a>
            </Typography>
        </Stack>
    );
}