import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from "@mui/material";
import _ from 'lodash';
import useAppInfo from '../../../../hooks/appInfo.hook';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        fontSize: 12,
        lineHeight: '15px'
    },
    bullet: {
        background: '#636363',
        borderRadius: 9,
        height: 12,
        marginRight: 6,
        width: 12,
    },
    appName: {
        color: '#636363',
        marginRight: 0
    },
    arrow: {
        backgroundImage: `url(${require('../../../../assets/img/push/arrow.svg').default})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 100%',
        width: 15
    },
    arrowTop: {
        backgroundImage: `url(${require('../../../../assets/img/push/arrow.svg').default})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '100% 100%',
        width: 15,
        transform: 'rotateX(180deg)'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        WebkitBoxPack: 'justify',
        justifyContent: 'space-between',
        marginTop: 8,
        maxWidth: 273
    },
    image: {
        display: 'flex',
        width: '100%',
        maxHeight: 160,
        objectFit: 'cover',
        marginTop: 10,
        backgroundColor: 'rgb(254, 254, 254)',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        WebkitBoxPack: 'center',
        justifyContent: 'center'
    }
});

export default function PushPreviewAndroid({ payload }) {
    const { appInfo } = useAppInfo()

    const [expanded, setExpanded] = useState(false);
    const imageUrl = _.get(payload, 'image.url', null);
    const canExpand = payload.text.length > 45 || !_.isEmpty(imageUrl) || payload.text.includes('\n');

    const classes = useStyles({ canExpand, expanded, imageUrl });

    return (
        <Card
            onClick={() => setExpanded(!expanded)}
            sx={{
                borderRadius: '2px',
                cursor: canExpand ? 'pointer' : 'auto',
                fontFamily: 'Roboto, "Open Sans", Arial, Helvetica, sans-serif;',
                marginTop: '255px',
                padding: '16px',
                position: 'absolute',
                marginLeft: '30px',
                width: '340px'
            }}
        >
            <div className={classes.header}>
                <span className={classes.bullet} />
                <span className={classes.appName}>{_.isEmpty(appInfo.title) ? 'Meu aplicativo' : appInfo.title} · agora</span>
                {canExpand && <span className={expanded ? classes.arrowTop : classes.arrow} />}
            </div>

            <Box sx={{
                alignItems: 'flex-start',
                display: expanded ? 'block' : 'flex',
                justifyContent: 'space-between'
            }}>
                <div className={classes.textContainer}>
                    <Typography sx={{
                        color: 'rgb(83, 83, 83)',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '400',
                        letterSpacing: '0.1px',
                        margin: 0,
                        display: 'inline-block',
                        overflow: 'hidden',
                        width: _.isEmpty(imageUrl) ? '280px' : '260px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        {_.isEmpty(payload.title) ? '' : payload.title}
                    </Typography>
                    <Typography sx={{
                        color: 'rgb(117, 117, 117)',
                        display: '-webkit-box',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '400',
                        letterSpacing: '0.1px',
                        margin: 0,
                        overflow: 'hidden',
                        width: _.isEmpty(imageUrl) ? '280px' : '260px',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: expanded && _.isEmpty(imageUrl) ? 'unset' : '1',
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'pre-line'
                    }}>
                        {_.isEmpty(payload.text) ? 'Esse é o texto da minha notificação' : payload.text}
                    </Typography>
                </div>

                {!expanded && imageUrl &&
                    <Box component='span' sx={{
                        alignSelf: 'flex-start',
                        backgroundImage: `url("${imageUrl}")`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        marginTop: '8px',
                        width: '37px',
                        height: '37px'
                    }} />
                }

                {expanded && imageUrl &&
                    <img className={classes.image} src={imageUrl} />
                }
            </Box>
        </Card>
    )
}



