import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    label: {
        display: "block",
        margin: "0",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    },
    input: {
        display: "block",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        width: "100%",
        height: "2.77083rem",
        margin: "0 0",
        padding: "0.66667rem",
        border: "2px solid #000",
        borderRadius: "8px",
        backgroundColor: "#fff",
        WebkitBoxShadow: "none",
        boxShadow: "none",
        fontFamily: "inherit",
        fontSize: "1rem",
        fontWeight: "normal",
        lineHeight: 1.5,
        color: "#000",
        WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
        transition: [
            "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            "box-shadow 0.5s, border-color 0.25s ease-in-out",
            "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
        ],
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        backgroundImage:
            "url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20version%3D%271.1%27%20width%3D%2732%27%20height%3D%2724%27%20viewBox%3D%270%200%2032%2024%27%3E%3Cpolygon%20points%3D%270%2C0%2032%2C0%2016%2C24%27%20style%3D%27fill%3A%20rgb%2528133%2C%20133%2C%20133%2529%27%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E)",
        backgroundOrigin: "content-box",
        backgroundPosition: "right center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "9px 6px",
        "&:disabled": {
            backgroundColor: "#e6e6e6",
            cursor: "not-allowed"
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #3CD568",
            backgroundColor: "#fff",
            WebkitBoxShadow: "none",
            boxShadow: "none",
            WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            transition: [
                "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
                "box-shadow 0.5s, border-color 0.25s ease-in-out",
                "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
            ]
        }
    },
    error: {
        lineHeight: '1rem',
        "& label": {
            color: '#cc4b37!important'
        },
        "& input": {
            color: '#cc4b37!important',
            backgroundColor: '#faedeb',
            borderColor: '#cc4b37!important'
        },
        "& span": {
            color: '#cc4b37!important',
            fontSize: '.75rem'
        },
    },
}));

export default function MbSelectField({ children, disabled, error, label, name, register, inputProps, rest }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : ""}>
            <label className={classes.label}>{label}</label>
            <select
                className={classes.input}
                disabled={disabled}
                name={name}
                {...register}
                {...inputProps}
                {...rest}
            >
                {children}
            </select>
            {!!error && <span>{error}</span>}
        </div>
    );
}