import React, { useEffect, useState } from 'react';
import MainLayout from "../../../layouts/main";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MbControlledMaskedTextField, MbTextField } from '../../../components/MbTextFields';
import { mobilizeiUsersService } from '../../../services/mobilizei/users.service';
import useAuthentication from '../../../hooks/authentication.hook';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { showApiError } from '../../../utils/errors';
import HelloContainer from '../HelloContainer';
import ecommercePlatforms from '../../../utils/ecommercePlatforms';
import { useOnboarding } from '../../../contexts/onboarding.context';

export default function Signup() {
    const { control, handleSubmit, register, formState: { errors } } = useForm();

    const [isLoading, setIsLoading] = useState(false)
    const { setToken } = useAuthentication()
    const { data, setData } = useOnboarding();

    const onSubmit = (data) => {
        setIsLoading(true);

        if (data.phoneNumber) {
            data.phoneNumber = '+55' + data.phoneNumber;
        }

        if (data.email) {
            data.email = data.email.trim()
        }

        mobilizeiUsersService.create(data)
            .then(response => {
                setToken(response.token);
            })
            .catch(e => {
                showApiError(e.error);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const ecommercePlatformId = urlParams.get('platform_id');
        const storeId = urlParams.get('store_id');
        const storeName = urlParams.get('store_name');
        const storeUrl = urlParams.get('store_url');
        const platformToken = urlParams.get("platform_token");

        if (ecommercePlatformId && storeId && storeName && storeUrl) {
            const selectedPlatform = ecommercePlatforms.getById(ecommercePlatformId);
            if (selectedPlatform) {
                setData({
                    ...data,
                    ecommercePlatformId: selectedPlatform.id,
                    ecommercePlatformName: selectedPlatform.name,
                    ecommercePlatformToken: platformToken,
                    ecommercePlatformAllowsIframe: selectedPlatform.isIframeAllowed,
                    storeAccountUrl: storeUrl + selectedPlatform.storeAccountUrl,
                    storeCartUrl: storeUrl + selectedPlatform.storeCartUrl,
                    storeId,
                    storeName,
                    storeUrl
                })
            }
        }
    }, [])

    return (
        <MainLayout>
            <Grid container display="flex" direction="row" spacing={2} paddingX={{ xs: 2, lg: 20 }}>
                <Grid item xs={12} lg={6}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 6,
                        paddingBottom: 10,
                        minHeight: '100vh'
                    }}
                >
                    <Typography component="h1" variant="h2" textAlign="center">
                        Sua loja, seu app
                    </Typography>
                    <Typography sx={{ mt: 1.5 }} component="h2" fontWeight="normal" textAlign="center" variant="h6">
                        Já ajudamos centenas de lojas {data.ecommercePlatformName || ''} a impulsionar suas ações de marketing e vendas. Agora, você também pode ter o aplicativo da { data.storeName ? <b>{data.storeName}</b> : 'sua loja' } pronto para ser publicado em poucos minutos.
                    </Typography>
                    <Grid
                        container
                        component="form"
                        spacing={2}
                        maxWidth={"sm"}
                        direction={'row'}
                        justifyContent={'center'}
                        sx={{ mt: 4 }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid item xs={12} sm={9}>
                            <MbTextField
                                error={errors.name?.message}
                                label="Nome completo *"
                                register={register("name", {
                                    required: "O nome completo é obrigatório."
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <MbTextField
                                error={errors.email?.message}
                                label="E-mail *"
                                register={register("email", {
                                    required: "O e-mail é obrigatório.",
                                    pattern: {
                                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}|s$/,
                                        message: "O e-mail não é válido."
                                    }
                                })}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <MbTextField
                                error={errors.password?.message}
                                label="Senha *"
                                register={register("password", {
                                    required: "A senha é obrigatória."
                                })}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <MbControlledMaskedTextField
                                control={control}
                                error={errors.phoneNumber?.message}
                                format={"(##) #####-####"}
                                label="Número do WhatsApp"
                                name="phoneNumber"
                            />
                        </Grid>
                        {isLoading ? (
                            <Grid
                                container
                                justifyContent='center'
                                sx={{ mt: 3 }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={10} sm={6}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{ fontSize: '18px', mt: 3 }}
                                    >
                                        Testar 7 dias grátis
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="body1">
                                            Já tem uma conta?{' '}
                                            <Link href="/login" variant="body1">
                                                Faça seu login.
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={9} mt={2}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" align="justify">
                                    Ao se registrar, você concorda com nossos{' '}
                                    <Link href="https://mobilizei.com.br/termos-de-uso" target='_blank'>
                                        Termos de Uso
                                    </Link>{' '}
                                    e confirma que leu nossa{' '}
                                    <Link href="https://mobilizei.com.br/politica-de-privacidade" target='_blank'>
                                        Política de Privacidade.
                                    </Link>{' '}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <HelloContainer />
            </Grid>
        </MainLayout >
    );
}