import React, { useEffect, useState } from 'react';
import { useUser } from "../../../contexts/user.context"
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/Dashboard";
import { Box, Link, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import EditIcon from '@mui/icons-material/EditOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import PaletteIcon from '@mui/icons-material/PaletteOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import SmartphoneIcon from '@mui/icons-material/SmartphoneOutlined';
import useAppInfo from '../../../hooks/appInfo.hook';
import HelpCard from '../../../components/HelpCard';
import TaskCard from './TaskCard';
import TrialAlert from './TrialAlert';
import ActionBanner from './ActionBanner';
import AppStatus from './AppStatus';
import AppLoading from '../../../components/AppLoading';
import WelcomeDialog from './WelcomeDialog';
import SubscriptionSuccessDialog from './SubscriptionSuccessDialog';

export default function Main() {
    const navigate = useNavigate();
    const { user, isBasic } = useUser();
    const [isLoading, setIsLoading] = useState(true);

    // clear pricing redirections
    localStorage.setItem('pricingRedirections', 0);

    const [isSubscriptionSuccessDialogOpen, setIsSubscriptionSuccessDialogOpen] = useState(false)
    const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false)

    const {
        appInfo,
        isAppBeingPublished,
        isFirstPublishPending,
        showPricingOnPublishSection
    } = useAppInfo()

    useEffect(() => {
        if (!user.isOnboardingCompleted) {
            navigate('/onboarding');
            return;
        }

        // Check if we need to show the welcome dialog
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('swd')) {
            setIsWelcomeDialogOpen(true)
        }

        // Check if we need to show the subscription success dialog
        if (urlParams.get('ssd')) {
            setIsSubscriptionSuccessDialogOpen(true)
        }

        setIsLoading(false);
    }, [])

    const welcome = () => {
        return (
            <>
                <Typography variant="h6">
                    Te damos as boas-vindas à Mobilizei!
                </Typography>
                <Typography align="left" variant="subtitle1">
                    Sinta-se livre para explorar ou realize as tarefas abaixo para configurar o seu aplicativo:
                </Typography>

                <Box sx={{ justifyContent: { sm: 'start', xs: 'center' }, marginTop: 2 }} display="flex" flexWrap="wrap">
                    <TaskCard Icon={EditIcon} link="/general" text="Preencher dados da loja" />
                    <TaskCard Icon={ImageIcon} link="/images" text="Cadastrar imagens" />
                    <TaskCard Icon={PaletteIcon} link={isBasic ? "/editor" : "/theme"} text="Personalizar o layout" />
                    <TaskCard Icon={SmartphoneIcon} link="/preview" text="Testar no seu celular" />
                </Box>
            </>
        )
    }

    const appStatuses = () => {
        return (
            <>
                <Box display="flex" flexWrap="wrap" marginTop={2} sx={{ justifyContent: { sm: 'start', xs: 'center' } }}>
                    <AndroidIcon sx={{ color: "#2cc69d", marginRight: 1 }} />
                    <Typography align="left" variant="subtitle1">
                        Status do aplicativo Android
                    </Typography>
                </Box>

                <AppStatus
                    downloadUrl={appInfo.androidDownloadUrl}
                    isAppBeingPublished={isAppBeingPublished('android')}
                    platformName="Android"
                    publishStatus={appInfo.androidPublishStatus}
                    storeName="Play Store"
                    lastPublishRequestedAt={appInfo.androidLastPublishRequestedAt}
                    deadlineInDays={20}
                />
                <Box display="flex" flexWrap="wrap" marginTop={2} sx={{ justifyContent: { sm: 'start', xs: 'center' } }}>
                    <AppleIcon sx={{ color: "#1c93c3", marginRight: 1 }} />
                    <Typography align="left" variant="subtitle1">
                        Status do aplicativo iOS
                    </Typography>
                </Box>
                <AppStatus
                    downloadUrl={appInfo.iOSDownloadUrl}
                    isAppBeingPublished={isAppBeingPublished('ios')}
                    platformName="iOS"
                    publishStatus={appInfo.iOSPublishStatus}
                    storeName="App Store"
                    lastPublishRequestedAt={appInfo.iOSLastPublishRequestedAt}
                    deadlineInDays={20}
                />
            </>
        )
    }

    const actions = () => {
        return (
            <>
                <Typography marginTop={4} align="left" variant="subtitle1">
                    {isFirstPublishPending() ?
                        "Solicite a publicação do seu aplicativo" :
                        "Continue gerenciando o seu aplicativo"
                    }
                </Typography>

                <Box marginTop={2}>
                    {appInfo.storeStatus === 'on_trial' &&
                        <ActionBanner
                            icon={<img src={require(`../../../assets/img/logos/mobilizei_bw.png`)} height="35" />}
                            text="Ative sua conta Mobilizei para disponibilizar o aplicativo da sua loja para todos os seus clientes"
                            action={<Link variant='body2' to='/pricing'>Iniciar assinatura</Link>}
                        />
                    }

                    {!isFirstPublishPending() &&
                        <>
                            <ActionBanner
                                icon={<PaletteIcon fontSize='large' />}
                                text="Configure o seu tema e deixe o layout do seu aplicativo com a sua identidade visual"
                                action={<Link variant='body2' to='/theme'>Editar o layout</Link>}
                            />
                            <ActionBanner
                                icon={<SendIcon fontSize='large' />}
                                text="Escreva e envie uma mensagem para todos os usuários do seu aplicativo"
                                action={<Link variant='body2' to='/push'>Enviar notificação push</Link>}
                            />
                        </>
                    }

                    {appInfo.androidPublishStatus === 'first_publish_pending' &&
                        <ActionBanner
                            icon={<AndroidIcon fontSize="large" />}
                            text="Crie a sua conta de desenvolvedor do Google Play organizacional e solicite a publicação do seu aplicativo Android"
                            action={<Link variant='body2' to={showPricingOnPublishSection() ? '/pricing' : '/android'}>Publicar aplicativo Android</Link>}

                        />
                    }

                    {appInfo.iOSPublishStatus === 'first_publish_pending' &&
                        <ActionBanner
                            icon={<AppleIcon fontSize="large" />}
                            text="Crie a sua conta de desenvolvedor Apple empresarial e solicite a publicação do seu aplicativo iOS"
                            action={<Link variant='body2' to={showPricingOnPublishSection() ? '/pricing' : '/ios'}>Publicar aplicativo iOS</Link>}
                        />
                    }
                </Box>
            </>
        )
    }

    const tips = () => {
        return (
            (appInfo.androidPublishStatus === 'first_publish_pending' || appInfo.iOSPublishStatus === 'first_publish_pending') &&
            <>
                <Typography marginTop={4} align="left" variant="subtitle1">
                    Dicas Mobilizei
                </Typography>

                <Box marginTop={2}>
                    {appInfo.androidPublishStatus === 'first_publish_pending' &&
                        <ActionBanner
                            icon={<img src={require(`../../../assets/img/logos/play_store.png`)} height="35" />}
                            text="Como criar a sua conta de desenvolvedor do Google Play organizacional"
                            action={
                                <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-google-play" target="_blank">
                                    <Typography align="left" variant="body2" >
                                        Ler artigo
                                    </Typography>
                                </a>
                            }
                        />
                    }

                    {appInfo.iOSPublishStatus === 'first_publish_pending' &&
                        <ActionBanner
                            icon={<img src={require(`../../../assets/img/logos/app_store.png`)} height="35" />}
                            text="Como criar a sua conta de desenvolvedor Apple empresarial"
                            action={
                                <a href="https://mobilizei.com.br/tutoriais/como-criar-conta-desenvolvedor-apple" target="_blank">
                                    <Typography align="left" variant="body2" >
                                        Ler artigo
                                    </Typography>
                                </a>
                            }
                        />
                    }
                </Box>
            </>
        )
    }

    // This is here to prevent flickering when
    // redirecting the user to the onboarding
    if (isLoading)
        return <AppLoading />

    return (
        <DashboardLayout
            Icon={HomeIcon}
            title="Painel"
        >
            <Box sx={{ padding: '24px', px: { md: '96px', sm: '64px' } }}>
                {appInfo.storeStatus === 'on_trial' && <TrialAlert />}
                {isFirstPublishPending() ? welcome() : appStatuses()}
                {actions()}
                {tips()}
                <HelpCard
                    link="https://mobilizei.com.br/tutoriais"
                    linkText="Veja os nossos tutoriais"
                />
            </Box>

            <WelcomeDialog 
                isOpen={isWelcomeDialogOpen} 
                setIsOpen={setIsWelcomeDialogOpen} 
            />
            
            <SubscriptionSuccessDialog 
                isOpen={isSubscriptionSuccessDialogOpen} 
                setIsOpen={setIsSubscriptionSuccessDialogOpen} 
            />
        </DashboardLayout>
    )
}
