import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import PaletteIcon from '@mui/icons-material/Palette';
import { useUser } from '../../../contexts/user.context';
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import { mobilizeiThemesService } from '../../../services/mobilizei/themes.service';
import DashboardLayout from '../../../layouts/Dashboard';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { showApiError } from '../../../utils/errors';

export default function Theme() {
    const navigate = useNavigate();

    const { user, setUser } = useUser();

    const activeThemeKey = user?.store?.activeTheme;

    const [isLoading, setIsLoading] = useState(true)
    const [showResetThemeModal, setShowResetThemeModal] = useState(false)

    const [themes, setThemes] = useState([])
    const [activeTheme, setActiveTheme] = useState({})

    useEffect(() => {
        mobilizeiThemesService.getThemes()
            .then(themes => {
                // Get the active theme
                const activeTheme = themes.find(t => t.key === activeThemeKey);

                // Filter the themes array to remove the active theme
                themes = themes.filter(t => t.key !== activeThemeKey);

                setActiveTheme(activeTheme);
                setThemes(themes);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [activeThemeKey]);

    const handleThemeSelect = (themeKey) => {
        setIsLoading(true);

        const data = { activeTheme: themeKey }

        mobilizeiStoresService.updateStoreById(user?.store?.platformId, user?.store?.id, data)
            .then(() => {
                toast.success('Tema selecionado com sucesso!');

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({ ...user, store: _.merge(user.store, data) });
            })
            .catch(e => {
                showApiError(e.error);
                setIsLoading(false);
            })
    }

    const handleThemeReset = () => {
        setIsLoading(true);

        mobilizeiStoresService.resetThemeById(user?.store?.platformId, user?.store?.id, activeThemeKey)
            .then(() => {
                toast.success('As configurações do tema foram reiniciadas!');
            })
            .catch(e => {
                showApiError(e.error);
            })
            .finally(() => {
                setIsLoading(false);
                setShowResetThemeModal(false);
            })
    }

    return (
        <DashboardLayout
            Icon={PaletteIcon}
            title="Temas"
            subtitle="Escolha e personalize o tema do seu aplicativo"
        >
            {
                isLoading ? (
                    <Box display='flex' alignItems="center" justifyContent="center" height='100%'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ padding: { sm: '24px', xs: '16px' } }}>
                        <Box>
                            <Typography variant='h6' marginBottom='8px'>
                                {_.get(activeTheme, 'meta.name', '')}
                                <Typography marginLeft='12px' component='span' variant="body2">Este é seu tema atual</Typography>
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item sm={5} xs={12}>
                                    <img style={{ maxWidth: '100%' }} src={_.get(activeTheme, 'meta.imageUrl', '')} />
                                </Grid>

                                <Grid item sm={7} xs={12}>
                                    <Card sx={{ boxShadow: 'none', marginBottom: '32px' }}>
                                        <Typography variant="subtitle1" component="h2">
                                            Faça com que seu app seja único!
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Configure o seu tema e deixe o layout do seu aplicativo com a sua identidade visual
                                        </Typography>
                                        <Button
                                            color='secondary'
                                            onClick={() => navigate('/editor')}
                                            sx={{ marginTop: '8px' }}
                                            variant="outlined"
                                        >
                                            Personalizar seu tema atual
                                        </Button>
                                    </Card>
                                    <Card sx={{ boxShadow: 'none', marginBottom: '32px' }}>
                                        <Typography variant="subtitle1" component="h2">
                                            Deseja voltar às configurações iniciais?
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Clique no botão abaixo para retornar às configurações iniciais do seu tema atual
                                        </Typography>
                                        <Button
                                            color='secondary'
                                            onClick={() => setShowResetThemeModal(true)}
                                            sx={{ marginTop: '8px' }}
                                            variant="outlined"

                                        >
                                            Reiniciar configurações
                                        </Button>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Typography variant='h6' marginTop='64px' marginBottom='8px'>Outros temas</Typography>
                            <Grid container spacing={3}>
                                {themes.map((theme, index) => {
                                    return (
                                        <Grid item lg={4} sm={6} xs={12} key={`theme-${index}`}>
                                            <Card>
                                                <CardContent sx={{ paddingBottom: 0 }}>
                                                    <img style={{ maxWidth: '100%' }} src={_.get(theme, 'meta.imageUrl', '')} alt="product" />
                                                    <Typography variant="body2" component="p" minHeight='4lh'>
                                                        {_.get(theme, 'meta.description', '')}
                                                    </Typography>
                                                </CardContent>

                                                <CardActions>
                                                    <Button variant='outline' sx={{ p: 1 }} onClick={() => handleThemeSelect(theme.key)}>
                                                        Usar este tema
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>

                        <Dialog
                            open={showResetThemeModal}
                            onClose={() => setShowResetThemeModal(false)}
                        >
                            <DialogContent id="alert-dialog-title">
                                <Typography align="justify" variant="h5">
                                    Reiniciar configurações
                                </Typography>
                            </DialogContent>
                            <DialogContent>
                                <Typography align="justify" variant="subtitle1" className="mb-32">
                                    Você tem certeza que deseja retornar às configurações iniciais do seu
                                    tema? Você irá perder <b>TODAS</b> as configurações que realizou!
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ p: 3 }}>
                                <Button onClick={() => setShowResetThemeModal(false)}>
                                    Cancelar
                                </Button>
                                <Button size='small' variant='contained' onClick={() => handleThemeReset()}>
                                    Reiniciar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                )
            }
        </DashboardLayout>
    )
}