import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import BillingDataForm from '../../../../components/BillingDataForm';
import { useUser } from '../../../../contexts/user.context';
import { toast } from 'react-toastify';
import { showApiError } from '../../../../utils/errors';
import SaveButton from '../../../../components/SaveButton';
import { mobilizeiSubscriptionsService } from '../../../../services/mobilizei/subscriptions.service';
import { useForm } from 'react-hook-form';

export default function BillingData() {
    const { user, setUser } = useUser();
    const [ isLoading, setIsLoading ] = useState(false);
    const { control, handleSubmit, setValue, watch, reset, register, formState: { errors, isDirty } } = useForm({
        defaultValues: {
            cpfCnpj: user?.subscription?.billingInformation?.cpfCnpj,
            email: user?.subscription?.billingInformation?.email,
            name: user?.subscription?.billingInformation?.name,
            phone: user?.subscription?.billingInformation?.phone,
            zipCode: user?.subscription?.billingInformation?.zipCode,
            number: user?.subscription?.billingInformation?.number,
            street: user?.subscription?.billingInformation?.street,
            city: user?.subscription?.billingInformation?.city,
            state: user?.subscription?.billingInformation?.state,
            district: user?.subscription?.billingInformation?.district,
            complement: user?.subscription?.billingInformation?.complement,
        }
    });

    const onSubmit = (data) => {
        if(!data){
            return
        }

        if (data.email) {
            data.email = data.email.trim()
        }

        setIsLoading(true)

        mobilizeiSubscriptionsService.updateBillingInformation(user?.subscription?.id, data).then(() => {
            toast.success('Alterações salvas com sucesso!');

            // Update the local user data to match the data that was 
            // updated on the backend
            setUser({ ...user, subscription: { 
                ...user?.subscription, 
                billingInformation: _.merge(user?.subscription?.billingInformation, data) 
            }});

            // Calling the reset function with an object will update the form defaultValues 
            // and subsequent calls to reset() will use the new default values
            reset(data);
        })
        .catch(e => {
            showApiError(e.error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }
    
    return (
        <Box mb={6}>
            <Typography variant="h5">
                Dados de faturamento
            </Typography>
            <Typography align="left" variant="subtitle2" sx={{ marginBottom: 2 }} color="textSecondary">
                As informações abaixo serão utilizadas para a emissão das suas notas fiscais
            </Typography>

            <BillingDataForm
                control={control}
                watch={watch}
                register={register}
                errors={errors}
                setValue={setValue}
            />
            
            <SaveButton 
                isLoading={isLoading} 
                onCancel={() => reset()}
                show={isDirty}
                onClick={handleSubmit(onSubmit)}
            />
        </Box>
    );
}