import React from 'react';
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import _ from "lodash";
import { MbTextField } from '../../../components/MbTextFields';
import DeleteIcon from '@mui/icons-material/Delete';
import PushForm from '../Push/PushForm';

const useStyles = makeStyles({
    loadingContainer: {
        height: '100%'
    },
    card: {
        boxShadow: 'none',
        marginBottom: 32
    },
    themeCard: {
        padding: 0
    },
    label: {
        display: "block",
        margin: "0",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    },
});

export default function NotificationCard({ notification, deleteNotification, handleChange, index }) {
    const classes = useStyles();

    return (
        <Box marginBottom={4}>
            <Card elevation={4}>
                <CardContent>
                    <Box flexDirection="row" display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography className="h2" display='inline'>
                            NOTIFICAÇÃO #{index + 1}
                        </Typography>
                        <IconButton onClick={() => deleteNotification()}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>

                    <Grid
                        container
                        spacing={2}
                        flexDirection={'row'}
                    >
                        <Grid item xs={12}>
                            <MbTextField
                                helper={`Caracteres restantes: ${60 - notification.tag.length}`}
                                id="tag"
                                inputProps={{ maxLength: 60 }}
                                label="Tag da Sellflux"
                                name="tag"
                                onChange={(e) => handleChange('tag', e.target.value, index)}
                                value={notification.tag}
                            />

                            <PushForm
                                onPayloadChange={(key, value) => handleChange(key, value, index)}
                                payload={notification}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}