import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { useOnboarding } from '../../../contexts/onboarding.context';
import { PLATFORMS, platformsOnboardingIntegration } from '../../../utils/constants';
import NuvemshopIntegration from '../../../components/platformIntegrations/NuvemshopIntegration';
import WixIntegration from '../../../components/platformIntegrations/WixIntegration';

export default function EcommercePlatformIntegration() {
    const { data, goToNextStep } = useOnboarding();
    const [integrationComponent, setIntegrationComponent] = useState(<div />);

    useEffect(() => {
        if (platformsOnboardingIntegration.includes(data.ecommercePlatformId)) {
            switch (data.ecommercePlatformId) {
                case PLATFORMS.NUVEMSHOP:
                    setIntegrationComponent(<NuvemshopIntegration />);
                    break;
                case PLATFORMS.WIX:
                    setIntegrationComponent(<WixIntegration />);
                    break;
            }
        } else {
            goToNextStep()
        }
    }, [data.ecommercePlatformId])

    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {integrationComponent}
        </Box>
    );
}