import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function SubscriptionActivateDialog({ isOpen, setIsOpen, onConfirm }) {
    return (
        <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle display='flex' alignItems='center'>
                <WarningAmberIcon sx={{ mr: 1 }} />
                <Typography variant="title1">
                    Reativar sua assinatura?
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Typography align="justify" variant="body1" sx={{ mb: 2 }}>
                    Ao reativar sua assinatura, próximo da sua data de vencimento, uma nova fatura será gerada. Esta fatura deverá ser paga para manter a sua assinatura ativa.
                </Typography>
            </DialogContent>

            <DialogActions sx={{ py: 2, px: 3 }}>
                <Button onClick={() => setIsOpen(false)}>
                    não, desejo manter a minha assinatura cancelada
                </Button>
                <Button variant="contained" onClick={onConfirm}>
                    sim, desejo reativar a minha assinatura
                </Button>
            </DialogActions>
        </Dialog>
    )
}