import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useOnboarding } from '../../../../contexts/onboarding.context';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    bottom: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-evenly',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    zIndex: 10
  },
  button: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 75
  },
  icon: {
    fontSize: 18
  },
  label: {
    fontSize: 12,
    margin: '4px 0 0 0'
  }
});

export default function TabBar({ data, onButtonClick }) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Box sx={{ backgroundColor: data?.options?.backgroundColor }}>
      <ul className={classes.root}>
        {data?.content?.map((section, index) => (
          <Box
            key={`tbb-${index}`}
            sx={{
              color: selectedIndex === index ? data?.options?.activeIconColor : data?.options?.iconColor
            }}
          >
            <li
              className={classes.button}
              onClick={() => {
                if (section.action !== "goToWhatsapp") setSelectedIndex(index);
                onButtonClick(section);
              }}
              style={{ display: 'flex' }}
            >
              <FontAwesomeIcon icon={[section?.icon?.style, section?.icon?.name]} className={classes.icon} fixedWidth />
              <p className={classes.label}>{section.title}</p>
            </li>
          </Box>
        ))}
      </ul>
    </Box>
  );
}