import React from 'react';
import Grid from '@mui/material/Grid';
import HelloImg from '../../assets/img/hello.png';

export default function HelloContainer() {
    return (
        <Grid item xs={6}
            sx={{
                alignItems: 'center',
                display: { xs: 'none', lg: 'flex' },
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 6,
                paddingBottom: 10,
                minHeight: '100%'
            }}
        >
            <img alt="Hello" src={HelloImg} style={{ width: '100%' }} />
        </Grid>
    );
}