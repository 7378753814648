import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import useAppInfo from '../hooks/appInfo.hook';
import { mobileAppPlatforms } from '../utils/constants';

export default function PublishingWarning() {
    const { isAppBeingPublished } = useAppInfo()

    if (isAppBeingPublished(mobileAppPlatforms.ANDROID) || isAppBeingPublished(mobileAppPlatforms.IOS)) {
        return (
            <Card
                elevation={1}
                sx={{
                    background: '#fff9c4',
                    mt: 4,
                    mb: 2,
                    p: 1
                }}
            >
                <CardContent sx={{ alignItems: 'center', display: 'flex' }} >
                    <WarningIcon sx={{ color: "#744210", fontSize: 35, mr: 2 }} />
                    <Typography sx={{ color: "#744210" }} align="justify" variant="body1" >
                        Uma nova versão do seu aplicativo está em processo de aprovação. Novas alterações 
                        só podem ser efetuadas nesta seção depois da finalização deste processo.
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return null;
}