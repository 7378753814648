import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAppInfo from '../../hooks/appInfo.hook';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../assets/img/logo.svg'

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import PaletteIcon from '@mui/icons-material/PaletteOutlined';
import SmartphoneIcon from '@mui/icons-material/SmartphoneOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuthentication from '../../hooks/authentication.hook';
import { useUser } from '../../contexts/user.context';
import { PLATFORMS } from '../../utils/constants';

const drawerWidth = 280;

export default function DrawerMenu() {
    const { appInfo, isBothAppVersionsPublished, showPricingOnPublishSection } = useAppInfo()
    const { logout } = useAuthentication()
    const { isBasic, user } = useUser();

    const location = useLocation()
    const navigate = useNavigate();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const isBasicPlan = () => {
        if(isBasic && user?.store?.id !== "0yocOuWnMM548MCw94mM"){
            return true
        }

        return false
    }

    let drawerItens = [
        {
            type: 'item',
            title: 'Página inicial',
            route: '/',
            Icon: HomeIcon,
        },
        {
            type: 'subheader',
            title: 'Notificações'
        },
        {
            type: 'item',
            title: 'Carrinho abandonado',
            Icon: ShoppingCartIcon,
            route: '/abandoned-cart-notifications',
            hide: isBasicPlan()
        },
        {
            type: 'item',
            title: 'Enviar notificação push',
            Icon: SendIcon,
            route: '/push'
        },
        {
            type: 'item',
            title: 'Sellflux',
            Icon: SchemaOutlinedIcon,
            route: '/sellflux',
            hide: true
        },
        {
            type: 'item',
            title: 'Último produto visto',
            Icon: PreviewOutlinedIcon,
            route: '/last-viewed-product-notification',
            hide: true
        },
        {
            type: 'subheader',
            title: 'Configurar lojas'
        },
        {
            type: 'item',
            title: 'Dados gerais',
            Icon: EditIcon,
            route: '/general'
        },
        {
            type: 'item',
            title: 'Imagens',
            Icon: ImageIcon,
            route: '/images'
        },
        {
            type: 'subheader',
            title: 'Personalizar'
        },
        {
            type: 'item',
            title: 'Layout',
            Icon: PaletteIcon,
            route: isBasic ? '/editor' : '/theme'
        },
        {
            type: 'subheader',
            title: 'Testar',
            hide: isBothAppVersionsPublished()
        },
        {
            type: 'item',
            title: 'Ver seu aplicativo no celular',
            Icon: SmartphoneIcon,
            route: '/preview',
            hide: isBothAppVersionsPublished()
        },
        {
            type: 'subheader',
            title: 'Publicar'
        },
        {
            type: 'item',
            title: 'Aplicativo Android',
            Icon: AndroidIcon,
            route: showPricingOnPublishSection() ? '/pricing-android' : '/android'
        },
        {
            type: 'item',
            title: 'Aplicativo iOS',
            Icon: AppleIcon,
            route: showPricingOnPublishSection() ? '/pricing-ios' : '/ios'
        },
    ]

    const isDrawerItemSelected = (item) => {
        if (item.route === '/') {
            return location.pathname === '/';
        } else {
            return location.pathname.includes(item.route)
        }
    }

    const listItemButtonStyle = {
        color: '#000',
        px: 3,
        py: 0.4,
    }

    const listItemIconStyle = di => ({
        color: di && isDrawerItemSelected(di) ? '#fff' : '#000',
        minWidth: '35px',
        fontSize: 20
    })


    const drawer = (
        <Box display='flex' flexDirection='column' height='100%' minHeight={700} position='relative'>
            <Box display='flex' alignItems='center' justifyContent='center' height={35} my={2} sx={{ cursor: "pointer" }}>
                <img src={Logo} alt="logo" height={35} onClick={() => navigate('/')} />
            </Box>

            <Box display='flex' flexDirection='column' flexGrow={1} justifyContent='space-between'>
                <List>
                    {drawerItens.map((di, index) => (
                        !di.hide && (
                            di.type === 'item' ? (
                                <ListItem disablePadding key={`item-${index}`}>
                                    <ListItemButton
                                        onClick={() => navigate(di.route)}
                                        sx={{
                                            ...listItemButtonStyle,
                                            ...(isDrawerItemSelected(di)) && {
                                                backgroundColor: 'primary.main',
                                                borderBottomRightRadius: 20,
                                                borderTopRightRadius: 20,
                                                color: '#fff',
                                                mr: 2,
                                                pointerEvents: 'none'
                                            }
                                        }}
                                    >
                                        <ListItemIcon sx={listItemIconStyle(di)}>
                                            <di.Icon sx={{ fontSize: 18 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={di.title}
                                            primaryTypographyProps={{ fontSize: 15 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ) : (
                                <ListSubheader
                                    key={`subheader-${index}`}
                                    component="div"
                                    sx={{
                                        color: '#000',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        lineHeight: '20px',
                                        pb: 1,
                                        pt: 2,
                                        px: 3,
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {di.title}
                                </ListSubheader>
                            )
                        )
                    ))}
                </List>

                <List sx={{ mt: 5, width: '100%' }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => navigate('/account')}
                            sx={{
                                ...listItemButtonStyle,
                                ...(isDrawerItemSelected({ route: "/account" })) && {
                                    backgroundColor: 'primary.main',
                                    borderBottomRightRadius: 20,
                                    borderTopRightRadius: 20,
                                    color: '#fff',
                                    mr: 2,
                                    pointerEvents: 'none'
                                }
                            }}
                        >
                            <ListItemIcon sx={listItemIconStyle()}>
                                <PersonIcon sx={{ fontSize: 18 }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Minha conta'
                                primaryTypographyProps={{ fontSize: 15 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => window.open('https://mobilizei.com.br/suporte')}
                            sx={listItemButtonStyle}
                        >
                            <ListItemIcon sx={listItemIconStyle()}>
                                <HelpIcon sx={{ fontSize: 18 }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Suporte'
                                primaryTypographyProps={{ fontSize: 15 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => logout()}
                            sx={listItemButtonStyle}
                        >
                            <ListItemIcon sx={listItemIconStyle()}>
                                <LogoutIcon sx={{ fontSize: 18 }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Sair'
                                primaryTypographyProps={{ fontSize: 15 }}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                color='white'
                position="fixed"
                sx={{
                    display: { lg: 'none' },
                    ml: { lg: `${drawerWidth}px` },
                    width: { lg: `calc(100% - ${drawerWidth}px)` }
                }}
            >
                <Toolbar sx={{ padding: '0!important' }}>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerToggle}
                        sx={{ display: { lg: 'none' }, mx: 1.5 }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        height: '64px',
                        width: '1px',
                    }} />

                    <Box display='flex' sx={{ ml: 2 }}>
                        <img src={Logo} alt="logo" height={35} />
                    </Box>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{
                    boxShadow: '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
                    flexShrink: { lg: 0 },
                    width: { lg: drawerWidth }
                }}
            >
                {/* Mobile drawer */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>

                {/* Desktop drawer */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}
