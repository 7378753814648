import * as React from 'react';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';

export default function SubscriptionSuccessDialog({ isOpen, setIsOpen }) {
    return (
        <Dialog open={isOpen}>
            <Grid container sx={{ paddingX: 6, paddingY: 3 }} display='flex' flexDirection='column' alignItems='center'>
                <Box alignItems="center" display="flex" justifyContent="center" mb={2}>
                    <img src={require(`../../../assets/img/billing/check.png`)} alt="check" width="60" />
                </Box>

                <Typography component="h1" variant="h5" align='center'>
                    Assinatura realizada com sucesso
                </Typography>

                <Typography sx={{ mt: 3 }} component="h2" variant="body1" align='center'>
                    Seja bem-vindo(a) à Mobilizei! Em breve você receberá um e-mail com a confirmação da sua assinatura.
                </Typography>

                <Typography sx={{ mt: 3 }} component="h2" variant="body1" align='center'>
                    Clique no botão abaixo para acessar o nosso painel de controle e configurar o seu aplicativo.
                </Typography>

                <Grid item xs={8} sm={6} display='flex' alignItems='center'>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => setIsOpen(false)}
                    >
                        Acessar painel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}