import React from 'react';
import _ from 'lodash';
import { Box, Typography } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BlockIcon from '@mui/icons-material/Block';
import ErrorIcon from '@mui/icons-material/Error';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';

export default function Warnings(props) {
    return (
        <div>
            {props.platformName === 'iOS' && (
                <Box display='flex' alignItems='center' marginBottom={3}>
                    <ErrorIcon sx={{ mr: 4 }} fontSize='large' />
                    <Typography align="justify" variant="subtitle1">
                        O seu aplicativo iOS só será publicado caso você tenha uma conta de desenvolvedor Apple do tipo empresarial ativa e tenha concedido um acesso à sua conta para o email suporte@mobilizei.com.br.
                    </Typography>
                </Box>
            )}
            {props.platformName?.toLowerCase() === 'android' && (
                <Box display='flex' alignItems='center' marginBottom={3}>
                    <ErrorIcon sx={{ mr: 4 }} fontSize='large' />
                    <Typography align="justify" variant="subtitle1">
                        O seu aplicativo Android só será publicado caso você tenha uma conta de desenvolvedor Google Play do tipo organizacional ativa e, tenha concedido um acesso à sua conta para o email suporte@mobilizei.com.br.
                    </Typography>
                </Box>
            )}
            <Box display='flex' alignItems='center' marginBottom={3}>
                <CalendarTodayIcon sx={{ mr: 4 }} fontSize='large' />
                <Typography align="justify" variant="subtitle1">
                    O prazo de publicação da versão {props.platformName} do seu aplicativo é de cerca de <b>{props.deadlineInDays} dias úteis.</b> Esse prazo pode variar para mais ou para menos de acordo com a velocidade da revisão realizada {props.platformName === 'iOS' ? 'pela Apple' : 'pelo Google'}.
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' marginBottom={3}>
                <BlockIcon sx={{ mr: 4 }} fontSize='large' />
                <Typography align="justify" variant="subtitle1">
                    Não é possível suspender um pedido de publicação já disparado. Assim, só é possível solicitar uma nova publicação quando a anterior for concluída.
                </Typography>
            </Box>

            {!_.isEmpty(props.downloadUrl) && (
                <Box display='flex' alignItems='center' marginBottom={3}>
                    <SystemUpdateIcon sx={{ mr: 4 }} fontSize='large' />
                    <Typography align="justify" variant="subtitle1">
                        Seu aplicativo continuará disponível para download enquanto a nova versão está em fase de aprovação.
                    </Typography>
                </Box>
            )}
        </div>
    )
}