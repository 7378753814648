import { PLATFORMS } from '../utils/constants';
import { nuvemshopService as ncs } from './mobilizei/nuvemshop.service';
import { trayService as tcs } from './mobilizei/tray.service';
import { categoriesService as wcs } from './wix/categories.service';
import { mapNuvemCategories } from '../utils/mappers/nuvemshop';
import { mapTrayCategories } from '../utils/mappers/tray';
import { mapWixCategories } from '../utils/mappers/wix';

export const categoriesServices = {
    getAllCategories
};

function getAllCategories(platformId, storeId, accessToken) {
    let queryMethod = null;
    let mapperMethod = null;
    if (platformId === PLATFORMS.NUVEMSHOP) {
        queryMethod = ncs.getAllCategories;
        mapperMethod = mapNuvemCategories;
    } else if(platformId === PLATFORMS.TRAY) {
        queryMethod = tcs.getAllCategories;
        mapperMethod = mapTrayCategories;
    } else {
        queryMethod = wcs.getAllCategories;
        mapperMethod = mapWixCategories;
    }

    return queryMethod(storeId, 1, [], accessToken).then(response => {
        const categories = mapperMethod(response);
        return categories;
    })
}