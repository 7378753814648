import React from 'react';
import { Box, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Typography } from '@mui/material';
import { formatIsoTimestamp, mapIntegerInCentsToCurrency } from '../../../utils/mappers';

export default function BillingDetails({ invoice }) {
    const { dueDate, items, totalInCents } = invoice;

    return (
        <Box mb={6}>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={7}>
                    <Typography variant="h5">
                        Detalhes da Fatura
                    </Typography>
                </Grid>
                <Grid container item xs={5} direction="column" justifyContent="flex-end">
                    <Typography variant="subtitle2" align="right">
                        Vencimento
                    </Typography>
                    <Typography variant="subtitle1" align="right">
                        {formatIsoTimestamp(dueDate, 'DD/MM/YYYY')}
                    </Typography>
                </Grid>

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                                <TableRow key={`billing-item-${index}`}>
                                    <TableCell component="th" scope="row" sx={{ color: '#858585'}}>
                                        {item.description}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right" sx={{ color: '#858585'}}>
                                        {mapIntegerInCentsToCurrency(item.priceInCents)}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell />
                                <TableCell align="right" >
                                    <Typography variant="h6" style={{color: '#000'}}>
                                        Total
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="h6" style={{color: '#000'}}>
                                        {mapIntegerInCentsToCurrency(totalInCents)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    );
}